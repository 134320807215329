import paper from 'paper'
import utils from '@/js/utils'

export default class CoverPanel {
  constructor(name, data, region, grainDirection) {
    //  PRIVATE
    this.data = data
    this.region = region
    this.grainDirection = grainDirection
    this.labelSize = 40
    this.labelPadding = 75
    this.sheetThickness = data.material.thickness
    this.requiresMaterialColorDot =
      data.type === 'end-b' ||
      data.type === 'end-w' ||
      data.type === 'top' ||
      data.type === 'underside' ||
      data.type === 'shelf'

    // v-plinth
    this.numOfVPlinthGrooves = 3
    this.vPlinthGrooveHeight = 8
    this.vPlinthGrooveSpacing = 20

    this.calculateSize()

    this.createCoverPanel()
    var label = this.getLabel()

    this.grain = this.data.type === 'back' ? this.getGrainDirection() : null
    this.outline.name = 'outline'
    this.dimension = this.getDimension()
    this.dimension.name = 'dimension'
    let itemGroup = [this.outline, label, this.dimension]
    if (this.grain) {
      this.grain.name = 'grain'
      itemGroup.push(this.grain)
    }
    this.item = new paper.Group([...itemGroup])
    this.item.name = name
    this.item.data.originalBounds = this.item.bounds
  }

  calculateSize() {
    switch (this.data.type) {
      // side on panels
      case 'end-b':
      case 'end-w':
        this.size = new paper.Size(this.sheetThickness, this.data.h)
        break
      case 'shelf':
      case 'underside':
      case 'top':
        this.size = new paper.Size(this.data.w, this.sheetThickness)
        break
      case 'v-plinth':
      case 'plinth':
      case 'filler':
      case 'back':
      case 'custom':
        this.size = new paper.Size(this.data.w, this.data.h)
        break
    }
  }

  createCoverPanel() {
    var rect = new paper.Rectangle(new paper.Point(0, 0), this.size)
    this.outline = new paper.Path.Rectangle(rect)

    if (this.data.type == 'v-plinth') {
      const ventStartX = this.data.ventRef === 'r' ? this.data.w - this.data.ventOffset : this.data.ventOffset
      const ventEndX =
        this.data.ventRef === 'r'
          ? this.data.w - this.data.ventOffset - this.data.vent
          : this.data.ventOffset + this.data.vent
      const ventOffsetY =
        (this.data.h - (this.numOfVPlinthGrooves - 1) * this.vPlinthGrooveSpacing - this.vPlinthGrooveHeight) / 2
      const topVentGrooveRec = new paper.Rectangle(
        new paper.Point(ventStartX, ventOffsetY),
        new paper.Point(ventEndX, ventOffsetY + this.vPlinthGrooveHeight)
      )
      const midVentGrooveRec = new paper.Rectangle(
        new paper.Point(ventStartX, ventOffsetY + this.vPlinthGrooveSpacing),
        new paper.Point(ventEndX, ventOffsetY + this.vPlinthGrooveSpacing + this.vPlinthGrooveHeight)
      )
      const botVentGrooveRec = new paper.Rectangle(
        new paper.Point(ventStartX, ventOffsetY + 2 * this.vPlinthGrooveSpacing),
        new paper.Point(ventEndX, ventOffsetY + 2 * this.vPlinthGrooveSpacing + this.vPlinthGrooveHeight)
      )
      const ventRad = new paper.Size(8, 8)

      const topVentGroovePath = new paper.Path.Rectangle(topVentGrooveRec, ventRad)
      const midVentGroovePath = new paper.Path.Rectangle(midVentGrooveRec, ventRad)
      const botVentGroovePath = new paper.Path.Rectangle(botVentGrooveRec, ventRad)
      // const allVentGrooves = new paper.Group([topVentGroovePath, midVentGroovePath, botVentGroovePath])

      this.outline = this.outline.subtract(topVentGroovePath)
      this.outline = this.outline.subtract(midVentGroovePath)
      this.outline = this.outline.subtract(botVentGroovePath)
      topVentGroovePath.remove()
      midVentGroovePath.remove()
      botVentGroovePath.remove()
    }

    if (
      this.data.type == 'filler' ||
      this.data.type == 'back' ||
      this.data.type == 'v-plinth' ||
      this.data.type == 'plinth' ||
      this.data.type == 'custom'
    ) {
      this.outline.fillColor = this.data.material.uiColour ? this.data.material.uiColour.toString() : '#FFFFFF'
      this.outline.strokeColor = utils.isColourDark(this.data.material.uiColour) ? 'white' : 'black'
      // this.outline.strokeColor = 'black'
    } else {
      this.outline.fillColor = '#FBE6CB'
      this.outline.strokeColor = 'black'
    }
  }

  getLabel() {
    const text = new paper.PointText(new paper.Point(0, 0))
    text.justification = 'center'
    text.fillColor = 'black'
    text.selectedColor = 'transparent'
    text.fontSize = this.labelSize
    text.fontFamily = 'Roboto Mono'

    let label
    switch (this.data.type) {
      case 'end-b':
      case 'end-w':
        label = 'CP' + this.data.itemNum
        break
      case 'v-plinth':
        label = 'VPLINTH'
        break
      case 'plinth':
        label = 'PLINTH'
        break
      case 'filler':
        label = 'FP' + this.data.itemNum
        break
      case 'back':
        label = 'BK' + this.data.itemNum
        break
      case 'top':
        label = 'TP' + this.data.itemNum
        break
      case 'underside':
        label = 'U' + this.data.itemNum
        break
      case 'custom':
        label = 'C' + this.data.itemNum
        break
      case 'shelf':
        label = 'Shelf'
        break
      default:
        console.log('No label for cover panel type', this.data.type)
        label = this.data.itemNum.toString()
    }

    text.content = label.toUpperCase()

    let dotty = false
    if (this.requiresMaterialColorDot) {
      dotty = new paper.Path.Circle(new paper.Point(0, 0), 20)
      dotty.fillColor = this.data.material.uiColour
      dotty.selectedColor = 'transparent'
      dotty.strokeColor = 'black'
    }

    switch (this.data.type) {
      case 'top':
        text.translate(this.size.width / 2, -this.labelPadding)
        if (dotty) dotty.translate(this.size.width / 2 - text.bounds.width / 2 - 40, -this.labelPadding - 14)
        break
      case 'underside':
      case 'shelf':
        text.translate(this.size.width / 2, text.bounds.height + this.labelPadding)
        if (dotty)
          dotty.translate(this.size.width / 2 - text.bounds.width / 2 - 40, text.bounds.height + this.labelPadding - 14)
        break
      case 'end-b':
      case 'end-w':
        text.rotate(-90)

        text.translate(this.sheetThickness / 2 + 2, this.size.height + text.bounds.height / 2 + this.labelPadding)

        if (dotty)
          dotty.translate(
            this.sheetThickness / 2,
            this.size.height + text.bounds.height + this.labelPadding + 40
            // this.size.height + text.bounds.width / 2 + this.labelPadding + 80
          )
        break
      case 'filler':
        if (this.size.height >= this.size.width) {
          text.rotate(-90)
        }
        text.translate(this.size.width / 2, this.size.height + text.bounds.height / 2 + this.labelPadding)
        break
      default:
        text.translate(this.size.width / 2, this.size.height + text.bounds.height + this.labelPadding)
        if (dotty) dotty.translate(this.size.width / 2, this.size.height + text.bounds.height + this.labelPadding + 80)
    }

    let lightIconGroup = []
    if (this.data.lighting) {
      const lightIconPath =
        'M748 782 c-32 -13 -436 -374 -441 -394 -14 -50 41 -107 87 -91 36 12 436 363 448 392 12 33 -6 75 -38 90 -28 12 -32 13 -56 3z' +
        'M1296 769 l-26 -20 0 -305 c0 -291 1 -305 20 -324 11 -11 33 -20 50 -20 17 0 39 9 50 20 19 19 20 33 20 324 l0 305 -26 20 c-15 12 -34 21 -44 21 -10 0 -29 -9 -44 -21z' +
        'M1874 778 c-28 -13 -46 -53 -38 -83 7 -27 411 -385 450 -398 46 -16 100 41 87 91 -5 20 -409 381 -441 394 -23 9 -33 8 -58 -4z'

      const makeLightIcon = (posX, posY, deg) => {
        const lightIcon = new paper.CompoundPath(lightIconPath)
        lightIcon.scale(0.04)
        lightIcon.rotate(deg)
        lightIcon.position = new paper.Point(posX, posY)
        lightIcon.fillColor = '#f73123'
        lightIcon.selectedColor = 'transparent'
        return lightIcon
      }

      const lightIconDownL = makeLightIcon(this.size.width / 5, this.sheetThickness * 2, 180)
      const lightIconDownR = makeLightIcon((this.size.width * 4) / 5, this.sheetThickness * 2, 180)

      lightIconGroup.push(lightIconDownL, lightIconDownR)
    }

    // return dotty ? new paper.Group([text, dotty]) : text

    let labelIconGroup = [text]
    if (this.data.lighting) labelIconGroup.push(...lightIconGroup)
    if (dotty) labelIconGroup.push(dotty)

    return new paper.Group(labelIconGroup)
  }

  getDimension() {
    let width, height
    this.region == 'us' ? (width = Math.round((this.data.w / 25.4) * 100) / 100) : (width = this.data.w)
    this.region == 'us' ? (height = Math.round((this.data.h / 25.4) * 100) / 100) : (height = this.data.h)
    let dimension = new paper.PointText(new paper.Point(this.data.w / 2, this.data.h / 2))
    dimension.justification = 'center'
    dimension.fillColor = 'transparent'
    dimension.selectedColor = 'transparent'
    dimension.fontSize = this.labelSize
    dimension.fontFamily = 'Roboto Mono'

    switch (this.data.type) {
      case 'filler':
        dimension.content = `W${width.toString().toUpperCase()} x H${height.toString().toUpperCase()}`
        dimension.translate(0, dimension.bounds.height / 4)
        if (this.data.h >= this.data.w) {
          dimension.rotate(-90)
        }
        break
      case 'end-b':
      case 'end-w':
        dimension.content = `W${width.toString().toUpperCase()} x H${height.toString().toUpperCase()}`
        dimension.rotate(-90)
        dimension.translate(-this.data.w / 2 - this.sheetThickness * 2, 0)
        break
      case 'v-plinth':
        dimension.content =
          this.data.vent && this.data.ventRef && this.data.ventOffset
            ? `W${this.data.w
                .toString()
                .toUpperCase()} x H${this.data.h
                .toString()
                .toUpperCase()} [W${this.data?.vent.toString().toUpperCase()} Vent from ${
                this.data?.ventRef === 'r' ? 'Right' : 'Left'
              } ${this.data?.ventOffset.toString().toUpperCase()}]`
            : `W${this.data.w.toString().toUpperCase()} x H${this.data.h.toString().toUpperCase()}`
        dimension.translate(0, dimension.bounds.height + 40)
        break
      case 'plinth':
      case 'back':
      case 'custom':
        dimension.content = `W${width.toString().toUpperCase()} x H${height.toString().toUpperCase()}`
        dimension.translate(0, dimension.bounds.height / 4)
        break
      case 'underside':
      case 'top':
      case 'shelf':
        dimension.content = `W${width.toString().toUpperCase()} x H${height.toString().toUpperCase()}`
        dimension.translate(0, -this.data.h / 2 - this.sheetThickness)
        break
      default:
        console.log('No info for cover panel type', this.data.type)
        dimension.content = `W${width.toString().toUpperCase()} x H${height.toString().toUpperCase()}`
    }
    return dimension
  }

  getGrainDirection() {
    if (this.data.material.hasGrain && this.grainDirection) {
      const grainIconPath =
        'M36.875,74.49023a3.99582,3.99582,0,0,1,.626-5.61328A77.36856,77.36856,0,0,1,65.02979,56.11914c15.38134-3.84375,39.17675-4.78906,65.189,12.55274,18.82227,12.54785,38.5,16.42773,58.48682,11.52734a70.77906,70.77906,0,0,0,24.80175-11.32715,3.99975,3.99975,0,0,1,4.9917,6.251,77.36856,77.36856,0,0,1-27.52881,12.75781,77.24806,77.24806,0,0,1-18.71533,2.31152c-13.38574.001-29.4541-3.51758-46.47363-14.86426C106.959,62.7793,87.28076,58.89941,67.29443,63.80078A70.77906,70.77906,0,0,0,42.49268,75.12793,4.00386,4.00386,0,0,1,36.875,74.49023Z' +
        'm176.63232,50.38184a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.02051-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,124.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90234,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,131.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z' +
        'm0,56a70.77906,70.77906,0,0,1-24.80175,11.32715c-19.98682,4.89941-39.66455,1.01953-58.48682-11.52734-26.01221-17.34083-49.80762-16.39649-65.189-12.55274A77.36856,77.36856,0,0,0,37.501,180.877a3.99975,3.99975,0,0,0,4.9917,6.251,70.77906,70.77906,0,0,1,24.80175-11.32715c19.98633-4.90332,39.66455-1.02051,58.48682,11.52734,17.01953,11.34668,33.08789,14.86524,46.47363,14.86426A79.44972,79.44972,0,0,0,218.499,187.12305a3.99975,3.99975,0,0,0-4.9917-6.251Z'

      const makeGrainIcon = (posX, posY, deg) => {
        const grainIcon = new paper.CompoundPath(grainIconPath)
        grainIcon.scale(0.3)
        grainIcon.rotate(deg)
        grainIcon.position = new paper.Point(posX, posY)
        grainIcon.strokeCap = 'round'
        grainIcon.fillColor = 'transparent'
        return grainIcon
      }

      let grainInfo
      if (this.data.opposingGrain) {
        if (Object.values(this.grainDirection)[0] === 'Vertical Grain') {
          grainInfo = makeGrainIcon(70, 70, 0)
        } else {
          grainInfo = makeGrainIcon(70, 70, 90)
        }
      } else {
        if (Object.values(this.grainDirection)[0] === 'Vertical Grain') {
          grainInfo = makeGrainIcon(70, 70, 90)
        } else {
          grainInfo = makeGrainIcon(70, 70, 0)
        }
      }
      return grainInfo
    } else {
      return null
    }
  }
}
