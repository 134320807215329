<template>
  <div id="materialOrderView">
    <div id="view-sub-nav">
      <v-spacer></v-spacer>
      <v-btn
        @click="$utils.copyToClipboard(frontsText.concat(insertsText).concat(worktopsText))"
        color="primary"
        dark
        outlined
        >Copy to Clipboard</v-btn
      >
    </div>
    <v-container fluid class="py-0">
      <v-row row wrap>
        <v-col cols="12" class="text-right"> </v-col>
        <v-col cols="12">
          <v-card class="mb-3">
            <v-card-title class="primary white--text">
              <span v-if="version">Sheets</span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :items="materials.concat(inserts, pantryLamBase, copiedMaterial)"
                :headers="headers"
                hide-default-footer
              >
                <template v-slot:item="props">
                  <tr v-if="props.item.qty > 0">
                    <td>{{ props.item.qty }}</td>
                    <td
                      @click="
                        () => {
                          selectedMaterial = props.item
                          qtyOverrideDialog = true
                        }
                      "
                    >
                      <div v-if="props.item.qtyOverride || props.item.qtyOverrideOffcut">
                        {{ props.item.qtyOverrideOffcut ? 'Offcut Used' : props.item.qtyOverride }}
                      </div>
                      <div v-else class="text--disabled">
                        Override sheet qty
                      </div>
                    </td>
                    <td>{{ props.item.thickness }}</td>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.dimensions }}</td>
                    <td>
                      <v-select
                        :items="props.item.sheetSizes"
                        :value="props.item.sheetOverride"
                        placeholder="Override sheet size"
                        hide-details
                        class="hide-line mt-0 pt-0 text-body-2"
                        @focus="selectedMaterial = props.item"
                        @click:clear="selectedMaterial = props.item"
                        @change="overrideSheetSize"
                        :clearable="props.item.isCopy ? false : true"
                        clear-icon="mdi-close-circle"
                      >
                      </v-select>
                    </td>
                    <td class="text-right">{{ props.item.colour }}</td>
                    <td class="d-flex flex-row justify-end">
                      <v-tooltip top :open-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            :disabled="
                              !props.item.colour ||
                                props.item.isCopy ||
                                props.item.sections ||
                                !['18mm'].includes(props.item.thickness) ||
                                props.item.sheetSizes.length == 0
                            "
                            @click="
                              () => {
                                selectedMaterial = props.item
                                copyMaterial()
                              }
                            "
                            v-on="on"
                            >mdi-content-copy</v-icon
                          >
                        </template>
                        <span>Copy</span>
                      </v-tooltip>
                      <v-tooltip top :open-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            class="ml-2"
                            :disabled="!props.item.isCopy"
                            @click="
                              () => {
                                selectedMaterial = props.item
                                deleteCopyMaterial()
                              }
                            "
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="worktops.length > 0">
          <v-card>
            <v-card-title class="primary white--text">
              <span>Worktops</span>
            </v-card-title>
            <v-card-text>
              <v-data-table :items="worktops" :headers="headers" hide-default-footer>
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.qty }}</td>
                    <td>n/a</td>
                    <td>{{ props.item.thickness }}</td>
                    <td>{{ props.item.material }}</td>
                    <td>{{ props.item.size }}</td>
                    <td>n/a</td>
                    <td class="text-right">{{ props.item.colour + ' / ' + props.item.colourName }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="qtyOverrideDialog" width="500">
      <v-card v-if="selectedMaterial">
        <v-card-title class="primary white--text title">Override Sheet Qty</v-card-title>
        <v-card-text class="pt-6">
          <v-progress-linear :indeterminate="true" v-if="selectedMaterial == null"></v-progress-linear>
          <v-checkbox v-if="selectedMaterial != null" label="Offcut Used" v-model="qtyOverrideOffcut"></v-checkbox>
          <v-text-field
            class="text-center"
            v-if="selectedMaterial != null && !qtyOverrideOffcut"
            v-model.number="qtyOverrideValue"
            placeholder="Number of sheets"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                qtyOverrideDialog = false
                qtyOverrideValue = null
              }
            "
            >Cancel</v-btn
          >
          <v-btn color="primary" depressed dark @click="overrideSheetQty">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Materials from '@/js/Materials'

export default {
  name: 'MaterialOrderView',
  props: ['shipment'],
  data() {
    return {
      quote: null,
      order: null,
      selectedMaterial: null,
      qtyOverrideDialog: false,
      qtyOverrideValue: null,
      qtyOverrideOffcut: false,
      headers: [
        {
          text: 'Qty',
          value: 'qty',
          sort: false
        },
        {
          text: 'Qty Override',
          value: 'nested',
          sort: false
        },
        {
          text: 'Thickness',
          value: 'thickness',
          sort: false
        },
        {
          text: 'Material',
          value: 'name',
          sort: false
        },
        {
          text: 'Quote Sheet Size',
          value: 'dimensions',
          sort: false
        },
        {
          text: 'Sheet Size Override',
          value: 'nestedSheet',
          sort: false
        },
        {
          text: 'Colour',
          value: 'colour',
          sort: false,
          align: 'right'
        },
        {
          text: 'Action',
          value: 'action',
          sort: false,
          align: 'right'
        }
      ]
    }
  },
  async created() {
    await this.$bind('quote', this.$db.collection('quotes').doc(this.shipment.quoteID))
    if (this.shipment.orderID) {
      await this.$bind('order', this.$db.collection('orders').doc(this.shipment.orderID))
    } else {
      // US Order View
      await this.$bind('order', this.$db.collection('orders').doc(this.shipment.id))
    }
  },
  computed: {
    version() {
      let v = null
      if (this.quote) {
        v = this.$utils.getVersionByID(this.quote.versions, this.shipment.versionID)
      }
      return v
    },
    materials() {
      var a = []
      if (!this.version) return []
      for (let m of this.version.materials) {
        if (
          this.version.materialUsage[m.id] &&
          this.version.materialUsage[m.id].areaWithWastage > 0 &&
          m.thickness !== 4
        ) {
          let material = Materials.getSheetSizesForType(m.value)
          let sheetSizes = material.sizes.filter(s => {
            if (s.w !== m.w && s.h !== m.w) {
              return s.thickness === m.thickness
            }
          })

          const sheetSizesOptions = []
          for (const size of sheetSizes) {
            sheetSizesOptions.push({
              text: size.h + ' x ' + size.w + ' - ' + (size.sides === 1 ? 'Single' : 'Double'),
              value: size
            })
          }

          var o = {
            id: m.id,
            qty: this.$utils.toFixedNumber(this.version.materialUsage[m.id].areaWithWastage / (m.w * m.h), 3),
            thickness: m.thickness + 'mm',
            name: m.label,
            dimensions: m.h + ' x ' + m.w,
            sides: m.sides,
            colour: m.colourCode,
            qtyOverride: this.version.materialUsage[m.id].qtyOverride,
            qtyOverrideOffcut: this.version.materialUsage[m.id].qtyOverrideOffcut,
            sheetOverride: this.version.materialUsage[m.id].sheetOverride,
            sheetSizes: sheetSizesOptions
          }
          a.push(o)
        }
      }

      return a
    },
    inserts() {
      if (!this.version || this.version.fronts.length == 0 || this.version.handles.length == 0) return []

      var a = []
      for (let handle of this.version.handles) {
        if (handle.insert) {
          const insertMaterial = this.version.materials.find(m => {
            return m.id === handle.insert
          })

          // We're not calculating exact usage of inserts so just 1 sheet per unique material
          if (insertMaterial.requiresColourCode && a.filter(e => e.id === insertMaterial.id).length === 0) {
            a.push({
              id: insertMaterial.id,
              qty: 1,
              thickness: insertMaterial.thickness + 'mm',
              name: insertMaterial.label,
              dimensions: insertMaterial.h + ' x ' + insertMaterial.w,
              colour: insertMaterial.colourCode
            })
          }
        }
      }
      return a
    },
    pantryLamBase() {
      if (!this.version || !this.version?.pantries || this.shipment.region == 'us') return []

      let a = []
      for (let pantry of this.version.pantries) {
        if (pantry.base && pantry.carcass != pantry.base) {
          const pantryBaseMaterial = this.version.materials.find(m => {
            return m.id === pantry.base
          })

          if (
            a.filter(e => e.id === pantryBaseMaterial.id).length == 0 &&
            this.materials.findIndex(material => material.id === pantryBaseMaterial.id) < 0
          ) {
            a.push({
              id: pantryBaseMaterial.id,
              qty: this.$utils.toFixedNumber(
                this.version.materialUsage[pantry.base].areaWithWastage / (pantryBaseMaterial.w * pantryBaseMaterial.h),
                3
              ),
              thickness: pantryBaseMaterial.thickness + 'mm',
              name: pantryBaseMaterial.label,
              dimensions: pantryBaseMaterial.h + ' x ' + pantryBaseMaterial.w,
              colour: pantryBaseMaterial.colourCode
            })
          }
        }
      }
      return a
    },
    copiedMaterial() {
      if (!this.version || !this.version?.copyMaterial) return []

      let a = []

      for (let copy of this.version.copyMaterial) {
        let sheetSizesOptions = []
        for (let m of this.version.materials) {
          if (
            this.version.materialUsage[m.id] &&
            this.version.materialUsage[m.id].areaWithWastage > 0 &&
            m.thickness !== 4 &&
            m.label === copy.name
          ) {
            let material = Materials.getSheetSizesForType(m.value)
            let sheetSizes = material.sizes.filter(s => {
              if (s.w !== m.w && s.h !== m.w) {
                return s.thickness === m.thickness
              }
            })

            for (const size of sheetSizes) {
              sheetSizesOptions.push({
                text: size.h + ' x ' + size.w + ' - ' + (size.sides === 1 ? 'Single' : 'Double'),
                value: size
              })
            }
          }
        }

        a.push({
          id: copy?.id,
          qty: copy?.qty,
          thickness: copy?.thickness,
          name: copy?.name,
          dimensions: copy?.dimensions,
          colour: copy?.colour,
          isCopy: copy?.isCopy,
          sheetSizes: sheetSizesOptions,
          qtyOverride: copy?.qtyOverride,
          qtyOverrideOffcut: copy?.qtyOverrideOffcut,
          sheetOverride: copy?.sheetOverride
        })
      }

      return a
    },
    worktops() {
      if (!this.version) return []

      var a = []
      for (let w of this.version.worktops) {
        let masterWorktops

        if (this.quote.region == 'uk') {
          masterWorktops = this.$store.state.worktops.filter(o => {
            return o.region === 'uk'
          })
        } else if (this.quote.region == 'us') {
          masterWorktops = this.$store.state.worktops.filter(o => {
            return o.region === 'us'
          })
        }

        const worktopMaterial = masterWorktops.find(o => {
          return o.value === w.material
        })

        const masterMaterial = this.$store.state.materials.find(o => {
          return worktopMaterial.material === o.value
        })

        const colourName = masterMaterial?.colours.find(o => {
          return o.value === w.colourCode
        })

        let sheetSize
        if (w.size.includes('x')) {
          sheetSize = w.size.split('x')[0] + ' x ' + w.size.split('x')[1]
        } else {
          sheetSize = w.size == 'regular' ? '1220 x 2440' : '1300 x 3050'
        }

        var o = {
          qty: 1,
          qtyOverride: null,
          material: worktopMaterial.text,
          colour: w.colourCode,
          colourName: colourName.text,
          thickness: w.thickness,
          faces: w.faces,
          size: sheetSize,
          sections: []
        }

        for (let s of w.sections) {
          var radiiString = !s.radii
            ? 'No radius specified'
            : 'Back Left ' +
              s.radii.bl +
              'mm  /  Back Right ' +
              s.radii.br +
              'mm  /  Front Right ' +
              s.radii.fr +
              'mm  /  Front Left ' +
              s.radii.fl +
              'mm'
          o.sections.push({
            length: s.l,
            depth: s.d,
            dogleg: s.dogleg ? s.dogleg : null,
            doglegLength: s.dogleg && s.li ? s.li : null,
            doglegDepth: s.dogleg && s.di ? s.di : null,
            radiiString: radiiString,
            coreThickness: s.customCoreThickness ? s.coreThickness : null
          })
        }
        a.push(o)
      }
      return a
    },
    frontsText() {
      let s = 'Order Number ' + this.order.orderNumber + '\n\n' + 'Fronts' + '\n\n'

      let frontsOrderList = this.materials.concat(this.copiedMaterial).toSorted((a, b) => a.name - b.name)

      for (let i = 0; i < frontsOrderList.length; i++) {
        let m = frontsOrderList[i]

        if (m?.qtyOverrideOffcut) continue
        const sheets = m.qtyOverride ? m.qtyOverride : m.qty

        const sheetSize = m.sheetOverride
          ? m.sheetOverride.thickness + ' x ' + m.sheetOverride.h + ' x ' + m.sheetOverride.w
          : m.thickness + ' x ' + m.dimensions

        let sheetSides = m?.sides == 2 ? 'double sided' : 'single sided'
        if (m.sheetOverride?.sides) {
          sheetSides = m.sheetOverride.sides == 2 ? 'double sided' : 'single sided'
        }

        if (!m.colour) {
          s += sheets + ' off ' + m.name + ' on ' + sheetSize + ' B/BB' + '\n'
        } else {
          if (m.name.split(' ').includes('Reeded')) {
            s +=
              sheets +
              ' off ' +
              m.name
                .split(' ')
                .slice(0, 3)
                .join(' ') +
              ' on ' +
              sheetSize +
              '\n'
          } else if (sheetSides == 'single sided') {
            if (m.thickness == '18mm') {
              s +=
                sheets +
                ' off ' +
                m.name +
                ' ' +
                m.colour +
                ' on ' +
                sheetSize +
                ' birch ply ' +
                sheetSides +
                ' (balancer back)' +
                '\n'
            } else {
              s +=
                sheets +
                ' off ' +
                m.name +
                ' ' +
                m.colour +
                ' on ' +
                sheetSize +
                ' birch ply ' +
                sheetSides +
                ' (bare birch ply back)' +
                '\n'
            }
          } else {
            s += sheets + ' off ' + m.name + ' ' + m.colour + ' on ' + sheetSize + ' birch ply ' + sheetSides + '\n'
          }
        }
      }

      return s
    },
    insertsText() {
      var s = ''
      for (var i = 0; i < this.inserts.length; i++) {
        var m = this.inserts[i]

        let sheetSides = m?.sides == 2 ? ' double sided' : ' birch ply single sided (bare birch ply back)'
        if (m.sheetOverride?.sides) {
          sheetSides = m.sheetOverride.sides == 2 ? ' double sided' : ' birch ply single sided (bare birch ply back)'
        }

        s += m.qty + ' off ' + m.name + ' ' + m.colour + ' on ' + m.thickness + ' x ' + m.dimensions + sheetSides + '\n'
      }
      return s + '\n\n'
    },
    worktopsText() {
      let s = 'Worktops' + '\n\n'
      for (let i = 0; i < this.worktops.length; i++) {
        let w = this.worktops[i]
        s +=
          w.material +
          ' ' +
          w.colourName +
          ' / ' +
          w.colour +
          ' on ' +
          w.thickness +
          ' x ' +
          w.size +
          ' birch ply ' +
          w.faces +
          ' sided'
        if (w.faces == 'single') {
          s += ' with balancer\n\n'
        } else {
          s += '\n\n'
        }

        for (let ii = 0; ii < w.sections.length; ii++) {
          let sec = w.sections[ii]
          s += '1 off ' + sec.length + ' x ' + sec.depth + ' with radii ' + sec.radiiString
          if (sec.coreThickness) s += ' - Upstand section mounted on ' + sec.coreThickness + 'mm'
          if (sec.dogleg && sec.doglegLength && sec.doglegDepth)
            s +=
              ' - Dogleg section with cutout of ' +
              'L' +
              sec.doglegLength +
              'mm x ' +
              'D' +
              sec.doglegDepth +
              'mm (see DXF plan)'
          s += '\n'
        }

        if (i < this.worktops.length - 1) s += '\n\n'
      }
      return s
    }
  },
  methods: {
    async overrideSheetQty() {
      let copyIndex = this.version.copyMaterial?.findIndex(copy => copy.id === this.selectedMaterial.id)
      if (copyIndex > -1) {
        this.version.copyMaterial[copyIndex].qtyOverride = this.qtyOverrideValue
        if (this.qtyOverrideOffcut) {
          this.version.copyMaterial[copyIndex].qtyOverrideOffcut = true
        } else {
          this.version.copyMaterial[copyIndex].qtyOverrideOffcut = false
        }
      } else {
        this.version.materialUsage[this.selectedMaterial.id].qtyOverride = this.qtyOverrideValue
        if (this.qtyOverrideOffcut) {
          this.version.materialUsage[this.selectedMaterial.id].qtyOverrideOffcut = true
        } else {
          this.version.materialUsage[this.selectedMaterial.id].qtyOverrideOffcut = false
        }
      }
      await this.$db
        .collection('quotes')
        .doc(this.quote.id)
        .update({
          versions: this.quote.versions
        })

      this.$store.commit('openSnackbar', { snackbarText: 'Sheet qty updated' })

      this.selectedMaterial = null
      this.qtyOverrideValue = null
      this.qtyOverrideOffcut = false
      this.qtyOverrideDialog = false
    },
    async overrideSheetSize(val) {
      let copyIndex = this.version.copyMaterial?.findIndex(copy => copy.id === this.selectedMaterial.id)
      if (copyIndex > -1) {
        this.version.copyMaterial[copyIndex].sheetOverride = val
      } else {
        this.version.materialUsage[this.selectedMaterial.id].sheetOverride = val
      }
      await this.$db
        .collection('quotes')
        .doc(this.quote.id)
        .update({
          versions: this.quote.versions
        })

      // We're not watching for changes so need to manually set on local variable
      this.$store.commit('openSnackbar', { snackbarText: 'Sheet size updated' })
      this.selectedMaterial = null
    },
    async copyMaterial() {
      // delete incase of undefined value
      this.selectedMaterial.qtyOverride = 1
      this.selectedMaterial.qtyOverrideOffcut = false
      this.selectedMaterial.sheetOverride = null

      // checking if theres already a copy
      let copyNum
      if (this.version?.copyMaterial && this.version?.copyMaterial.length > 0) {
        let foundCopyIndex = this.version?.copyMaterial.findLastIndex(
          copy => copy?.id.split('-')[0] === this.selectedMaterial.id
        )
        if (foundCopyIndex > -1) {
          copyNum = parseInt(this.version?.copyMaterial[foundCopyIndex].id.split('-')[1].slice(4))
          copyNum++
        }
      }

      // assigning copy number as uid and mark it as copy
      let cloneItem = Object.assign({}, this.selectedMaterial)
      if (copyNum) {
        cloneItem.isCopy = true
        cloneItem.id += '-COPY' + copyNum.toString()
      } else {
        cloneItem.isCopy = true
        cloneItem.id += '-COPY1'
      }

      // assign copy material's sheetOverride with initial sheetSizesOptions[0]
      let sheetSizesOptions = []
      for (let m of this.version.materials) {
        if (
          this.version.materialUsage[m.id] &&
          this.version.materialUsage[m.id].areaWithWastage > 0 &&
          m.thickness !== 4 &&
          m.label === this.selectedMaterial.name
        ) {
          let material = Materials.getSheetSizesForType(m.value)
          let sheetSizes = material.sizes.filter(s => {
            if (s.w !== m.w && s.h !== m.w) {
              return s.thickness === m.thickness
            }
          })

          for (const size of sheetSizes) {
            sheetSizesOptions.push({
              text: size.h + ' x ' + size.w + ' - ' + (size.sides === 1 ? 'Single' : 'Double'),
              value: size
            })
          }
        }
      }
      if (sheetSizesOptions) {
        let firstAltSheetOption = sheetSizesOptions[0].value
        cloneItem.sheetOverride = firstAltSheetOption
        // change copy dimensions
        cloneItem.dimensions = `${firstAltSheetOption.w} x ${firstAltSheetOption.h}`
        // change copy qty to 1
        cloneItem.qty = 1
      }

      if (!this.version?.copyMaterial || this.version?.copyMaterial.length == 0) {
        this.version.copyMaterial = []
        this.version.copyMaterial.push(cloneItem)
      } else {
        this.version.copyMaterial.push(cloneItem)
      }

      await this.$db
        .collection('quotes')
        .doc(this.quote.id)
        .update({
          versions: this.quote.versions
        })

      // We're not watching for changes so need to manually set on local variable
      this.$store.commit('openSnackbar', { snackbarText: 'Copied Sheet Info (does not change quote)' })
      this.selectedMaterial = null
    },
    async deleteCopyMaterial() {
      if (this.selectedMaterial.isCopy) {
        let deleteIndex = this.version?.copyMaterial.findIndex(copy => copy.id === this.selectedMaterial.id)
        if (deleteIndex > -1) this.version.copyMaterial.splice(deleteIndex, 1)
      }

      await this.$db
        .collection('quotes')
        .doc(this.quote.id)
        .update({
          versions: this.quote.versions
        })

      // We're not watching for changes so need to manually set on local variable
      this.$store.commit('openSnackbar', { snackbarText: 'Copy Deleted' })

      this.selectedMaterial = null
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .hide-line > .v-input__control > .v-input__slot:before {
  border: none;
}
</style>
