<template>
  <div id="us-orders">
    <div class="table-header">
      <v-switch
        color="primary"
        :label="includeDispatched ? 'Dispatched' : 'Awaiting Dispatch'"
        @change="includeDispatched = !includeDispatched"
        hide-details
        style="margin-right: auto"
      ></v-switch>
      <v-chip
        v-if="['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)"
        class="text-body-2 mr-4"
        color="green"
        >{{ usOrderTally }}</v-chip
      >
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      :items="filteredShipments"
      :headers="headers"
      :search="tableSearch"
      :custom-filter="customSearch"
      :loading="loading"
      :no-data-text="noDataText"
      sort-by="dateCreated"
      :items-per-page="50"
    >
      <template v-slot:item="props">
        <tr
          :class="{
            paused: props.item.paused
          }"
        >
          <td>{{ $utils.convertDate(props.item.dateCreated) }}</td>
          <td>
            <div class="d-flex justify-space-between">
              <div>
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="props.item.recut" small :size="14" class="mr-1" v-on="on">mdi-puzzle</v-icon>
                  </template>
                  <span>Partial recut of previous order</span>
                </v-tooltip>
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="props.item.balanceOutstanding" small :size="14" color="warning" v-on="on"
                      >mdi-alert</v-icon
                    >{{ props.item.orderNumber || props.item.xeroInvoiceNumber }}
                  </template>
                  <span>Outstanding balance - don't ship</span>
                </v-tooltip>
              </div>
              <div>
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      :size="14"
                      class="mr-1"
                      @click="
                        () => {
                          selectedShipment = props.item
                          editNotesDialog = true
                        }
                      "
                      v-on="on"
                      >mdi-comment</v-icon
                    >
                  </template>
                  <span>Order Notes</span>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td>{{ props.item.customerEmail }}</td>
          <td>
            {{ props.item.customerContactDetails.firstName + ' ' + props.item.customerContactDetails.lastName }}
          </td>
          <td>
            <DatePickerPlus
              :timestamp="props.item.targetDate"
              :clearable="true"
              placeholder="Target delivery date"
              v-on:update:timestamp="
                val => {
                  props.item.targetDate = val
                  changed(props.item, 'targetDate')
                }
              "
            ></DatePickerPlus>
          </td>
          <td>
            <DatePickerPlus
              :timestamp="props.item.inProduction"
              :clearable="true"
              :placeholder="'Requires toolpathing'"
              v-on:update:timestamp="
                val => {
                  props.item.inProduction = val
                  changed(props.item, 'inProduction')
                }
              "
            ></DatePickerPlus>
          </td>
          <td>
            <DatePickerPlus
              :timestamp="props.item.dispatchEta"
              :clearable="true"
              placeholder="TBC"
              v-on:update:timestamp="
                val => {
                  props.item.dispatchEta = val
                  changed(props.item, 'dispatchEta')
                }
              "
            ></DatePickerPlus>
          </td>

          <td>
            <v-text-field
              class="ma-0 pa-0"
              :style="{ fontSize: '13px' }"
              placeholder="Not shipped yet"
              v-model="props.item.trackingNumber"
              @change="changed(props.item, 'trackingNumber')"
              hide-details
            ></v-text-field>
          </td>
          <td class="text-right" style="min-width: 180px">
            <!-- Pause / Play -->
            <v-tooltip v-if="props.item.paused" top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  @click="
                    () => {
                      pauseShipmentDialog = true
                      selectedShipment = props.item
                    }
                  "
                  v-on="on"
                  >mdi-play-circle</v-icon
                >
              </template>
              <span>Unpause Shipment</span>
            </v-tooltip>
            <v-tooltip v-else top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  @click="
                    () => {
                      pauseShipmentDialog = true
                      selectedShipment = props.item
                      findQuoteFromShipment()
                    }
                  "
                  v-on="on"
                  >mdi-pause-circle</v-icon
                >
              </template>
              <span>Pause Shipment</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500" v-if="$store.state.user.permissions.uk">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="viewQuote(props.item)" v-on="on">mdi-clipboard-text</v-icon>
              </template>
              <span>Order Details</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="materialView(props.item)" v-on="on">mdi-cart</v-icon>
              </template>
              <span>Material Order List</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500" v-if="$store.state.user.permissions.us">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-2" @click="ludicPricingView(props.item)" v-on="on" :size="18"
                  >mdi-currency-usd</v-icon
                >
              </template>
              <span>Pricing</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="ludicProductionView(props.item)" v-on="on">mdi-wrench</v-icon>
              </template>
              <span>Lucid Production View</span>
            </v-tooltip>
            <v-tooltip v-if="!includeDispatched" top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  :disabled="!props.item.dispatchEta"
                  small
                  class="mr-2"
                  @click="
                    () => {
                      selectedOrder = props.item
                      shippedDialog = true
                    }
                  "
                  v-on="on"
                  >mdi-truck</v-icon
                >
              </template>
              <span>Mark order as shipped</span>
            </v-tooltip>
            <v-tooltip v-else top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  @click="
                    () => {
                      selectedOrder = props.item
                      unShippedDialog = true
                    }
                  "
                  v-on="on"
                  >mdi-truck</v-icon
                >
              </template>
              <span>Mark as NOT shipped</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Component Overlay Dialog -->
    <v-dialog v-model="dialogVisible" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" class="no-print elevation-0">
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              () => {
                dialogVisible = false
                overlayComponent = null
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <component
          :is="overlayComponent"
          :shipment="selectedOrder"
          :quoteID="selectedOrder.quoteID"
          :versionID="selectedOrder.versionID"
          :orderID="selectedOrder.id"
        ></component>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shippedDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          Mark as shipped
        </v-card-title>
        <v-card-text>
          Are you sure you want to mark this order as shipped?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="shippedDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="shipped(true)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unShippedDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          Mark as NOT shipped
        </v-card-title>
        <v-card-text>
          Are you sure you want to move the order back into production?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="unShippedDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="shipped(false)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Pause -->
    <v-dialog v-model="pauseShipmentDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ selectedShipment && selectedShipment.paused ? 'Unpause Shipment' : 'Pause Shipment' }}
        </v-card-title>
        <v-card-text v-if="selectedShipment && !selectedShipment.paused" class="pt-6">
          <div class="text-h6">Why?</div>
          <v-form ref="pauseForm">
            <v-textarea
              v-model="pauseForm.why"
              :rules="[v => !!v || 'A reason is required']"
              outlined
              placeholder="Please leave a brief note to explain why the order is being paused"
            ></v-textarea>
            <v-checkbox class="text-h6" v-model="pauseForm.changesCheck" label="Changes Have Been Made"></v-checkbox>
            <p v-if="pauseForm.changesCheck" class="text-caption red--text">
              Please state changes concisely.<br />
              Production notes will be generated from the latest pause; gather a full list of changes made.<br />
              Edits to the change statement can be made.
            </p>
            <div class="text-h6" v-if="pauseForm.changesCheck">Changes:</div>
            <v-textarea
              v-if="pauseForm.changesCheck"
              v-model="pauseForm.changes"
              outlined
              placeholder="List the changes in the format: [material] - [unit numbers] - [state changes]"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card
          v-else
          id="tasks-dialog"
          @input="
            () => {
              !pauseShipmentDialog
              selectedShipment = {}
            }
          "
        >
          <v-card-title class="primary white--text title">
            Pause Log
          </v-card-title>
          <v-card-text>
            <div v-for="(item, key) of this.selectedShipment.pauseLog" :key="key" class="pause-log-item mt-4">
              <div class="text-caption d-flex">
                <strong>
                  {{ `${item.author} - ${$moment.unix(item.date.seconds).format('Do MMM YYYY')}` }}
                </strong>
                <v-spacer></v-spacer>
                <v-icon
                  class="mr-1"
                  @click="
                    () => {
                      editPauseLogDialog = true
                      selectedPauseLog = item
                      selectedPauseLogKey = key
                      findQuoteFromShipment()
                    }
                  "
                  >mdi-pencil</v-icon
                >
                <v-icon
                  class="mr-1"
                  color="red"
                  @click="
                    () => {
                      deletePauseLogDialog = true
                      selectedPauseLog = item
                      selectedPauseLogKey = key
                      findQuoteFromShipment()
                    }
                  "
                  >mdi-delete
                </v-icon>
              </div>
              <div class="text-caption pt-2">Why?</div>
              <div class="text-body-1">{{ item.why }}</div>
              <div class="text-caption pt-2">Changes?</div>
              <div class="text-body-1">{{ item.changes || 'None' }}</div>
            </div>
          </v-card-text>
        </v-card>
        <div class="d-flex flex-column justify-end">
          <v-alert v-if="selectedShipment && selectedShipment.paused" type="warning" class="d-block">
            All work in production will restart if you unpause this shipment! <br />
            Please check that the latest change statement is accurate as this will be generated as production notes.
          </v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="pauseShipmentDialog = false">Cancel</v-btn>
            <v-btn color="warning" depressed dark @click="toggleShipmentPause(selectedShipment)">{{
              selectedShipment && selectedShipment.paused ? 'Unpause' : 'Pause'
            }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- EDIT PAUSE LOG -->
    <v-dialog v-model="editPauseLogDialog" width="500" @input="updatePauseLog(selectedPauseLogKey)">
      <v-card>
        <v-card-title class="primary title white--text">Edit Pause Log</v-card-title>
        <v-card-text class="mt-6">
          <div class="text-caption pt-2">Why</div>
          <div class="text-body-1 mb-2">{{ selectedPauseLog.why }}</div>
          <div class="text-caption pt-2">Edit Change Statement</div>
          <v-textarea v-model="selectedPauseLog.changes"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DELETE PAUSE LOG -->
    <v-dialog v-model="deletePauseLogDialog" width="500">
      <v-card>
        <v-card-title class="primary title white--text">Delete Pause Log</v-card-title>
        <v-card-text class="mt-6">
          <div class="text-caption pt-2">Why</div>
          <div class="text-body-1 mb-2">{{ selectedPauseLog.why }}</div>
          <div class="text-caption pt-2">Edit Change Statement</div>
          <div class="text-body-1 mb-2">{{ selectedPauseLog.changes }}</div>
        </v-card-text>
        <v-alert type="warning" class="pt-6 ">
          Are you sure you want to delete this pause log? <br />
          Remember the previous pause log will then be used for production notes.
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deletePauseLogDialog = false">Cancel</v-btn>
          <v-btn
            color="warning"
            depressed
            dark
            @click="deletePauseLog(selectedPauseLogKey)"
            :disabled="deletingPauseLog"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Notes dialog -->
    <v-dialog
      v-if="editNotesDialog"
      v-model="editNotesDialog"
      width="500"
      @input="
        () => {
          selectedShipment = {}
          selectedQuote = null
          selectedVersionIndex = null
        }
      "
    >
      <v-card>
        <v-card-text class="pt-3">
          <v-textarea outline label="Order notes" hide-details v-model="selectedShipment.notes"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text dark @click="editNotesDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="updateNotes">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Double Check dialog
    <v-dialog persistent v-model="editDblCheckDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          Quote Double Check
        </v-card-title>
        <v-card-text>
          <v-progress-linear :indeterminate="true" v-if="selectedShipment == null"></v-progress-linear>
          <v-checkbox
            label="Quote Has Been Verified with a Double Check"
            v-else
            v-model="dblCheck"
            :disabled="dblCheckSubmitting"
          >
          </v-checkbox>
        </v-card-text>
        <v-alert type="warning" v-if="selectedShipment.dblCheck && selectedShipment.toolpathed">
          This quote has been double checked and toolpathed on the date:
          <strong>{{ $utils.vuetifyTimestamp(selectedShipment.toolpathed) }}</strong> <br />
          By unchecking, you are invalidating the original toolpath and stating that the double-check was done
          incorrectly. <br />
          If that is the case, please initiate a re-toolpath!
        </v-alert>
        <v-alert type="warning" v-else-if="!selectedShipment.dblCheck && selectedShipment.toolpathed">
          This quote has been toolpathed on the date:
          <strong>{{ $utils.vuetifyTimestamp(selectedShipment.toolpathed) }}</strong> <br />
          If the double-check has occured after this date, please initiate a re-toolpath!
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            dark
            @click="
              () => {
                dblCheck = null
                editDblCheckDialog = false
              }
            "
            >Cancel</v-btn
          >
          <v-btn color="accent" depressed dark @click="updateDblCheck" :disabled="dblCheckSubmitting">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import QuoteEdit from '@/views/QuoteEdit'
import MaterialOrderView from '@/components/MaterialOrderView'
import LucidPricingView from '@/components/us/LucidPricingView'
import DatePickerPlus from '@/components/tables/DatePickerPlus'

export default {
  name: 'UsProduction',
  components: { QuoteEdit, MaterialOrderView, LucidPricingView, DatePickerPlus },
  data() {
    return {
      includeDispatched: false,
      tableSearch: '',
      orders: [],
      selectedOrder: {},
      loading: true,
      cloning: false,
      dblCheck: null,
      editDblCheckDialog: false,
      dblCheckSubmitting: false,
      editNotesDialog: false,
      headers: [
        {
          text: 'Date',
          sortable: true,
          value: 'dateCreated'
        },
        {
          text: 'Order / Inv #',
          sortable: true,
          value: 'invoiceNumber'
        },
        {
          text: 'Customer Email',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Customer Name',
          sortable: false,
          value: 'customerName'
        },
        {
          text: 'Target Date',
          sortable: true,
          value: 'targetDate'
        },
        {
          text: 'Toolpathed',
          sortable: true,
          value: 'inProduction'
        },
        {
          text: 'Dispatch ETA',
          sortable: true,
          value: 'dispatchEta'
        },
        {
          text: 'Tracking Link',
          sortable: false,
          value: 'trackingNumber'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          width: 160,
          value: null
        }
      ],
      overlayComponent: null,
      dialogTitle: null,
      dialogVisible: false,
      shippedDialog: false,
      unShippedDialog: false,
      pauseShipmentDialog: false,
      selectedQuote: null,
      selectedVersionIndex: null,
      selectedShipment: {},
      pauseForm: {
        why: null,
        changesCheck: null,
        changes: null
      },
      editPauseLogDialog: false,
      deletePauseLogDialog: false,
      deletingPauseLog: false,
      selectedPauseLog: {},
      selectedPauseLogKey: null
    }
  },
  computed: {
    noDataText() {
      return this.loading ? '' : 'Nothing to display'
    },
    usOrderTally() {
      if (!this.includeDispatched) {
        let usProductionOrders = this.orders.filter(order => {
          return order.shipped == false && order.foc == false && order.region == 'us'
        })
        let totalSum = 0
        for (const order of usProductionOrders) {
          totalSum += order?.total
        }
        return 'Orders Totals = $' + this.$numeral(totalSum).format('0,0.00')
      } else {
        let usDispatchedOrders = this.orders.filter(order => {
          return order.shipped == true && order.foc == false && order.region == 'us'
        })
        let totalSum = 0
        for (const order of usDispatchedOrders) {
          totalSum += order?.total
        }
        return 'Orders Totals = $' + this.$numeral(totalSum).format('0,0.00')
      }
    },
    filteredShipments() {
      if (!this.includeDispatched) {
        return this.orders.filter(order => {
          return order.shipped == false && (order.foc || order.numPayments > 0)
        })
      } else {
        return this.orders.filter(order => {
          return order.shipped == true
        })
      }
    }
  },
  methods: {
    changed(order, attribute) {
      // update in firebase
      this.$db
        .collection('orders')
        .doc(order.id)
        .update({
          [attribute]: order[attribute]
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Order Updated 🙌'
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        v =>
          v &&
          v
            .toString()
            .toLowerCase()
            .includes(search)
      )
    },
    shipped(isIt) {
      this.$db
        .collection('orders')
        .doc(this.selectedOrder.id)
        .update({
          shipped: isIt
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Order Updated 🙌'
          })
          this.shippedDialog = false
          this.unShippedDialog = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    formatLineItems(a) {
      var s = ''
      for (var i = 0; i < a.length; i++) {
        s += a[i]
        if (i < a.length - 1) s += ', '
      }
      return s
    },
    ludicPricingView(order) {
      this.dialogVisible = true
      this.selectedOrder = order
      this.dialogTitle = 'Lucid Pricing - ' + this.selectedOrder.customerEmail
      this.overlayComponent = 'LucidPricingView'
    },
    materialView(order) {
      this.dialogVisible = true
      this.selectedOrder = order
      this.dialogTitle = 'Material Order List'
      this.overlayComponent = 'MaterialOrderView'
    },
    ludicProductionView(order) {
      var routeData = this.$router.resolve({
        name: 'lucidProductionView',
        params: { orderID: order.id }
      })
      window.open(routeData.href, '_blank')
    },
    viewQuote(order) {
      this.dialogVisible = true
      this.selectedOrder = order
      this.dialogTitle = order.customerEmail
      this.overlayComponent = 'QuoteEdit'
    },
    toggleShipmentPause(shipment) {
      let pauseLog
      let updateObj = {
        paused: !this.selectedShipment.paused
      }
      if (!this.selectedShipment.paused) {
        // paused state
        if (!this.$refs.pauseForm.validate()) {
          return
        } else {
          if (!this.selectedShipment.pauseLog) {
            pauseLog = [
              {
                why: this.pauseForm.why,
                changes: this.pauseForm.changes ? this.pauseForm.changes : 'None',
                date: this.$firebase.firestore.Timestamp.now(),
                author: this.$firebase.auth().currentUser.email
              }
            ]
          } else {
            pauseLog = this.$firebase.firestore.FieldValue.arrayUnion({
              why: this.pauseForm.why,
              changes: this.pauseForm.changes ? this.pauseForm.changes : 'None',
              date: this.$firebase.firestore.Timestamp.now(),
              author: this.$firebase.auth().currentUser.email
            })
          }

          // save change statement (will always be latest pause log) in selectedQuote
          this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = this.pauseForm.changes
            ? this.pauseForm.changes
            : ''

          // then update to quotes db
          this.$db
            .collection('quotes')
            .doc(this.selectedShipment.quoteID)
            .update({
              versions: this.selectedQuote.versions
            })
            .then(() => {
              this.$store.commit('openSnackbar', {
                snackbarText: '🗒️ Changes added as Prod Notes!'
              })
              this.selectedQuote = null
              this.selectedVersionIndex = null
            })
        }
        updateObj.pauseLog = pauseLog
      }

      this.pauseShipmentDialog = false

      this.pauseForm.why = null
      this.pauseForm.changes = null
      if (this.pauseForm.changesCheck) this.pauseForm.changesCheck = null

      this.$db
        .collection('orders')
        .doc(shipment.id)
        .update(updateObj)
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Order Updated' })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    findQuoteFromShipment() {
      this.$db
        .collection('quotes')
        .doc(this.selectedShipment.quoteID)
        .get()
        .then(quoteRef => {
          this.selectedQuote = quoteRef.data()

          for (var i = 0; i < this.selectedQuote.versions.length; i++) {
            if (this.selectedQuote.versions[i].id == this.selectedShipment.versionID) {
              this.selectedVersionIndex = i
            }
          }
        })
    },
    updatePauseLog(key) {
      let updateObj = {
        paused: this.selectedShipment.paused
      }

      updateObj.pauseLog = this.selectedShipment.pauseLog

      this.editPauseLogDialog = false
      this.selectedPauseLog = {}

      this.$db
        .collection('orders')
        .doc(this.selectedShipment.id)
        .update(updateObj)
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Pause Log Updated' })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })

      // Trying to find order with same quote ID from selected shipment
      if (key && key == this.selectedShipment.pauseLog.length - 1) {
        // add latest pausedLog to selectedQuote as changeStatement
        this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = this.selectedShipment.pauseLog[
          key
        ].changes

        // then update to orders db to generate prod notes
        this.$db
          .collection('quotes')
          .doc(this.selectedShipment.quoteID)
          .update({
            versions: this.selectedQuote.versions
          })
          .then(() => {
            this.$store.commit('openSnackbar', {
              snackbarText: '🗒️ Changes added as Prod Notes!'
            })
            this.selectedQuote = null
            this.selectedVersionIndex = null
          })
      }
    },
    deletePauseLog(key) {
      this.deletePauseLogDialog = false
      this.deletingPauseLog = true

      let generateProdNote
      if (key == this.selectedShipment.pauseLog.length - 1 && key != 0) generateProdNote = true

      let clearChangeStatement
      if (key == 0) clearChangeStatement = true

      // then will delete that log!
      this.selectedShipment.pauseLog.splice(key, 1)

      if (generateProdNote) {
        let latest = this.selectedShipment.pauseLog.length - 1
        // add latest pausedLog to selectedQuote as changeStatement
        this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = this.selectedShipment.pauseLog[
          latest
        ].changes
      } else if (clearChangeStatement) {
        this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = ''
      }

      // then update to quotes db to generate prod notes
      this.$db
        .collection('quotes')
        .doc(this.selectedShipment.quoteID)
        .update({
          versions: this.selectedQuote.versions
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: '🗒️ Changes added as Prod Notes!'
          })
        })

      let updateObj = {
        paused: this.selectedShipment.paused
      }
      updateObj.pauseLog = this.selectedShipment.pauseLog
      this.$db
        .collection('orders')
        .doc(this.selectedShipment.id)
        .update(updateObj)
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Pause Log Updated' })
          this.deletingPauseLog = false
          this.selectedQuote = null
          this.selectedVersionIndex = null
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    async updateNotes() {
      this.editNotesDialog = false

      this.$db
        .collection('orders')
        .doc(this.selectedShipment.id)
        .update({
          notes: this.selectedShipment.notes
        })
      this.$store.commit('openSnackbar', {
        snackbarText: 'Notes Updated 🙌'
      })
    }
  },
  async created() {
    await this.$bind('orders', this.$db.collection('orders').where('region', '==', 'us'))
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
#us-orders {
  width: 100%;

  a {
    text-decoration: none;
  }
  table {
    border-top: solid 1px #e0e0e0;
    border-bottom: solid 1px #e0e0e0;
    th,
    td {
      padding: 0 10px;
      border-right: solid 1px #e0e0e0;
      &:last-of-type {
        border-right: none;
      }
    }
  }
}

.pause-log-item {
  padding: 8px;
  border: solid 1px grey;
}
</style>
