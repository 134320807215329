<template>
  <div id="production-table">
    <div class="table-header">
      <v-switch
        color="primary"
        label="Toolpath Mode"
        @change="
          () => {
            toolpathMode = !toolpathMode
          }
        "
        hide-details
        style="margin-right: auto"
      ></v-switch>
      <v-chip
        v-if="['sho@plykea.com', 'kath@plykea.com', 'adam@plykea.com'].includes($store.state.user.email)"
        class="text-body-2 mr-4"
        color="green"
        >{{ ukOrderTally }}</v-chip
      >
      <v-text-field
        v-model="tableSearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :clearable="true"
      ></v-text-field>
    </div>
    <v-data-table
      :items="shipments"
      :headers="headers"
      :search="tableSearch"
      :custom-filter="customSearch"
      :loading="loading"
      must-sort
      :sort-by="toolpathMode ? 'deliveryDate' : 'dateScheduled'"
    >
      <template v-slot:item="{ item }">
        <tr
          :class="{
            paused: item.paused,
            info: toolpathMode == true && !item.paused && item.toolpathed && item.materialOrdered,
            success: toolpathMode == true && !item.paused && item.toolpathed && !item.materialOrdered,
            error: toolpathMode == true && !item.paused && !item.toolpathed && !item.materialOrdered,
            rare: toolpathMode == true && !item.paused && !item.toolpathed && item.materialOrdered // shouldnt really happen
          }"
        >
          <td>
            <v-icon v-if="item.balanceOutstanding" small class="mr-2" color="warning">mdi-truck</v-icon>
            {{ item.orderNumber || item.invoiceNumber }}
          </td>
          <td :class="{ 'text-truncate': !tableSearch || tableSearch.length < 2 }">
            {{
              item.quoteEmail && item.quoteEmail !== item.customerEmail
                ? item.customerEmail + ' / ' + item.quoteEmail
                : item.customerEmail
            }}
          </td>
          <td>
            <div class="d-flex justify-space-between">
              <div>
                {{ formatLineItems(item.lineItems) }}
              </div>
              <div>
                <v-tooltip top :open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-icon small style="line-height: 14px; margin: 0 0 0 auto" @click="editNotes(item)" v-on="on"
                      >mdi-comment</v-icon
                    >
                  </template>
                  <span>Order Notes</span>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td>
            <DatePickerPlus
              :timestamp="item.materialOrdered"
              :clearable="true"
              placeholder="Material not ordered"
              v-on:update:timestamp="
                val => {
                  item.materialOrdered = val
                  changed(item, 'materialOrdered')
                }
              "
            ></DatePickerPlus>
          </td>
          <td>
            <DatePickerPlus
              :timestamp="item.toolpathed"
              :clearable="true"
              :placeholder="'Requires toolpathing'"
              v-on:update:timestamp="
                val => {
                  item.toolpathed = val
                  changed(item, 'toolpathed')
                }
              "
            ></DatePickerPlus>
          </td>
          <td>
            <div
              v-if="item.paymentDate"
              style="
								display: flex;
								align-items: center;
								justify-content: space-between;
							"
            >
              <div>
                {{ $utils.momentFromTimestamp(item.paymentDate).format('Do MMM') }}
              </div>
            </div>
          </td>
          <td>
            <div v-if="item.requiresSprayer !== false">
              <div
                v-if="item.sprayerDate"
                style="
									display: flex;
									align-items: center;
									justify-content: space-between;
								"
              >
                <div>
                  {{ $utils.momentFromTimestamp(item.sprayerDate).format('Do MMM') }}
                </div>
              </div>
            </div>
          </td>
          <td>
            <div
              style="
								display: flex;
								align-items: center;
								justify-content: space-between;
							"
            >
              <DatePickerPlus
                :timestamp="item.deliveryDate"
                :clearable="true"
                placeholder="Target Delivery"
                v-on:update:timestamp="
                  val => {
                    item.deliveryDate = val
                    changed(item, 'deliveryDate')
                  }
                "
              ></DatePickerPlus>
              <v-icon small class="ml-2" v-if="item.userSpecifiedDeliveryDate">mdi-account</v-icon>
            </div>
          </td>
          <td>
            <div
              v-if="item.dateScheduled"
              style="
								display: flex;
								align-items: center;
								justify-content: space-between;
							"
            >
              <div>
                {{ $utils.momentFromTimestamp(item.dateScheduled).format('Do MMM') }}
              </div>
            </div>
            <div v-else>Not scheduled</div>
          </td>
          <td class="text-right" style="min-width: 230px">
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="viewQuote(item)" v-on="on">mdi-clipboard-text</v-icon>
              </template>
              <span>Original Quote</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="materialView(item)" v-on="on">mdi-cart</v-icon>
              </template>
              <span>Material Order List</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="schedule(item)" v-on="on">mdi-calendar-range</v-icon>
              </template>
              <span>Schedule</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="sprayerView(item)" v-on="on">mdi-spray</v-icon>
              </template>
              <span>Sprayer List</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="packingView(item)" v-on="on">mdi-clipboard-check</v-icon>
              </template>
              <span>Packing List</span>
            </v-tooltip>
            <v-tooltip top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="printableSVG(item)" v-on="on">mdi-chart-tree</v-icon>
              </template>
              <span>Visual</span>
            </v-tooltip>
            <!-- Pause / Play -->
            <v-tooltip v-if="item.paused" top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  @click="
                    () => {
                      pauseShipmentDialog = true
                      selectedShipment = item
                    }
                  "
                  v-on="on"
                  >mdi-play-circle</v-icon
                >
              </template>
              <span>Unpause Shipment</span>
            </v-tooltip>
            <v-tooltip v-else top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  @click="
                    () => {
                      pauseShipmentDialog = true
                      selectedShipment = item
                      findQuoteFromShipment()
                    }
                  "
                  v-on="on"
                  >mdi-pause-circle</v-icon
                >
              </template>
              <span>Pause Shipment</span>
            </v-tooltip>
            <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  class="mr-2"
                  :disabled="item.balanceOutstanding"
                  @click="
                    () => {
                      dispatchedDialog = true
                      selectedShipment = item
                    }
                  "
                  v-on="on"
                  >mdi-truck</v-icon
                >
              </template>
              <span>Mark as dispatched</span>
            </v-tooltip>
            <v-tooltip v-if="$store.state.user.permissions.admin" top :open-delay="500">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  @click="
                    () => {
                      deleteShipmentDialog = true
                      selectedShipment = item
                    }
                  "
                  v-on="on"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Delete Shipment</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Component Overlay Dialog -->
    <v-dialog v-model="dialogVisible" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary" class="no-print elevation-0">
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              () => {
                dialogVisible = false
                overlayComponent = null
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <component
          :is="overlayComponent"
          :shipment="selectedShipment"
          :quoteID="selectedShipment.quoteID"
          :versionID="selectedShipment.versionID"
        ></component>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Pause -->
    <v-dialog v-model="pauseShipmentDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          {{ selectedShipment && selectedShipment.paused ? 'Unpause Shipment' : 'Pause Shipment' }}
        </v-card-title>
        <v-card-text v-if="selectedShipment && !selectedShipment.paused" class="pt-6">
          <div class="text-h6">Why?</div>
          <v-form ref="pauseForm">
            <v-textarea
              v-model="pauseForm.why"
              :rules="[v => !!v || 'A reason is required']"
              outlined
              placeholder="Please leave a brief note to explain why the order is being paused"
            ></v-textarea>
            <v-checkbox class="text-h6" v-model="pauseForm.changesCheck" label="Changes Have Been Made"></v-checkbox>
            <p v-if="pauseForm.changesCheck" class="text-caption red--text">
              Please state changes concisely.<br />
              Production notes will be generated from the latest pause; gather a full list of changes made.<br />
              Edits to the change statement can be made.
            </p>
            <div class="text-h6" v-if="pauseForm.changesCheck">Changes:</div>
            <v-textarea
              v-if="pauseForm.changesCheck"
              v-model="pauseForm.changes"
              outlined
              placeholder="List the changes in the format: [material] - [unit numbers] - [state changes]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card
          v-else
          id="tasks-dialog"
          @input="
            () => {
              !pauseShipmentDialog
              selectedShipment = {}
            }
          "
        >
          <v-card-title class="primary white--text title">
            Pause Log
          </v-card-title>
          <v-card-text>
            <div v-for="(item, key) of selectedShipment.pauseLog" :key="key" class="pause-log-item mt-4">
              <div class="text-caption d-flex">
                <strong>
                  {{ `${item.author} - ${$moment.unix(item.date.seconds).format('Do MMM YYYY')}` }}
                </strong>
                <v-spacer></v-spacer>
                <v-icon
                  class="mr-1"
                  @click="
                    () => {
                      editPauseLogDialog = true
                      selectedPauseLog = item
                      selectedPauseLogKey = key
                      findQuoteFromShipment()
                    }
                  "
                  >mdi-pencil</v-icon
                >
                <v-icon
                  class="mr-1"
                  color="red"
                  @click="
                    () => {
                      deletePauseLogDialog = true
                      selectedPauseLog = item
                      selectedPauseLogKey = key
                      findQuoteFromShipment()
                    }
                  "
                  >mdi-delete
                </v-icon>
              </div>
              <div class="text-caption pt-2">Why?</div>
              <div class="text-body-1">{{ item.why }}</div>
              <div class="text-caption pt-2">Changes?</div>
              <div class="text-body-1">{{ item.changes || 'None' }}</div>
            </div>
          </v-card-text>
        </v-card>
        <div class="d-flex flex-column justify-end">
          <v-alert v-if="selectedShipment && selectedShipment.paused" type="warning" class="d-block">
            All work in production will restart if you unpause this shipment! <br />
            Please check that the latest change statement is accurate as this will be generated as production notes.
          </v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="pauseShipmentDialog = false">Cancel</v-btn>
            <v-btn color="warning" depressed dark @click="toggleShipmentPause(selectedShipment)">{{
              selectedShipment && selectedShipment.paused ? 'Unpause' : 'Pause'
            }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- EDIT PAUSE LOG -->
    <v-dialog v-model="editPauseLogDialog" width="500" @input="updatePauseLog(selectedPauseLogKey)">
      <v-card>
        <v-card-title class="primary title white--text">Edit Pause Log</v-card-title>
        <v-card-text class="mt-6">
          <div class="text-caption pt-2">Why</div>
          <div class="text-body-1 mb-2">{{ selectedPauseLog.why }}</div>
          <div class="text-caption pt-2">Edit Change Statement</div>
          <v-textarea v-model="selectedPauseLog.changes"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DELETE PAUSE LOG -->
    <v-dialog v-model="deletePauseLogDialog" width="500">
      <v-card>
        <v-card-title class="primary title white--text">Delete Pause Log</v-card-title>
        <v-card-text class="mt-6">
          <div class="text-caption pt-2">Why</div>
          <div class="text-body-1 mb-2">{{ selectedPauseLog.why }}</div>
          <div class="text-caption pt-2">Edit Change Statement</div>
          <div class="text-body-1 mb-2">{{ selectedPauseLog.changes }}</div>
        </v-card-text>
        <v-alert type="warning" class="pt-6 ">
          Are you sure you want to delete this pause log? <br />
          Remember the previous pause log will then be used for production notes.
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deletePauseLogDialog = false">Cancel</v-btn>
          <v-btn
            color="warning"
            depressed
            dark
            @click="deletePauseLog(selectedPauseLogKey)"
            :disabled="deletingPauseLog"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteShipmentDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Delete Shipment</v-card-title>
        <v-card-text class="pt-6">
          Are you sure you want to delete this shipment and all associated tasks?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteShipmentDialog = false">Cancel</v-btn>
          <v-btn color="primary" depressed dark @click="deleteShipment(selectedShipment)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dispatchedDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Mark As Dispatched</v-card-title>
        <v-card-text class="pt-6">
          Are you sure you want to mark this shipment as dispatched?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dispatchedDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="markShipmentAsDispatched(selectedShipment)">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Schedule Dialog -->
    <v-dialog v-model="scheduleDialog" width="500" @input="scheduleDialogClose">
      <v-card id="tasks-dialog">
        <v-card-title class="primary white--text title">
          Task Schedule
        </v-card-title>
        <v-progress-linear color="accent" :indeterminate="true" v-if="taskSchedule == null"></v-progress-linear>
        <v-card-text class="pt-4">
          <div class="tasks">
            <div
              v-for="task in taskSchedule"
              :class="[task.data().type, { coloured: !task.data().complete }]"
              class="task d-flex justify-space-between pa-2 my-2 text-capitalize"
              :key="task.id"
            >
              <div>
                {{ task.data().type }} {{ task.data().objectType }}
                {{
                  task.data().type == 'dispatch' ? (task.data().subtype ? task.data().subtype : task.data().type) : ''
                }}
              </div>
              <div>
                {{
                  task.data().dateScheduled !== null
                    ? $utils.momentFromTimestamp(task.data().dateScheduled).format('ddd Do MMM YYYY')
                    : 'Unscheduled'
                }}
                <v-icon v-if="task.data().complete" small>mdi-check</v-icon>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2 pb-4">
          <v-btn color="accent" depressed dark @click="updateTasks(selectedShipment)">Update Tasks</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Double Check dialog
    <v-dialog persistent v-model="editDblCheckDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text title">
          Quote Double Check
        </v-card-title>
        <v-card-text>
          <v-progress-linear :indeterminate="true" v-if="selectedShipment == null"></v-progress-linear>
          <v-checkbox
            label="Quote Has Been Verified with a Double Check"
            v-else
            v-model="dblCheck"
            :disabled="dblCheckSubmitting"
          >
          </v-checkbox>
        </v-card-text>
        <v-alert type="warning" v-if="selectedShipment.dblCheck && selectedShipment.toolpathed">
          This quote has been double checked and toolpathed on the date:
          <strong>{{ $utils.vuetifyTimestamp(selectedShipment.toolpathed) }}</strong> <br />
          By unchecking, you are invalidating the original toolpath and stating that the double-check was done
          incorrectly. <br />
          If that is the case, please initiate a re-toolpath!
        </v-alert>
        <v-alert type="warning" v-else-if="!selectedShipment.dblCheck && selectedShipment.toolpathed">
          This quote has been toolpathed on the date:
          <strong>{{ $utils.vuetifyTimestamp(selectedShipment.toolpathed) }}</strong> <br />
          If the double-check has occured after this date, please initiate a re-toolpath!
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            dark
            @click="
              () => {
                dblCheck = null
                editDblCheckDialog = false
              }
            "
            >Cancel</v-btn
          >
          <v-btn color="accent" depressed dark @click="updateDblCheck" :disabled="dblCheckSubmitting">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Notes dialog -->
    <v-dialog
      v-model="editNotesDialog"
      width="500"
      @input="
        () => {
          selectedShipment = {}
          selectedQuote = null
          selectedVersionIndex = null
        }
      "
    >
      <v-card>
        <v-card-text>
          <v-progress-linear :indeterminate="true" v-if="selectedVersionIndex == null"></v-progress-linear>
          <v-textarea
            v-else
            outline
            label="Order notes"
            hide-details
            v-model="selectedQuote.versions[selectedVersionIndex].notes"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text dark @click="editNotesDialog = false">Cancel</v-btn>
          <v-btn color="accent" depressed dark @click="updateNotes" :disabled="notesSubmitting">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QuoteEdit from '@/views/QuoteEdit'
import MaterialOrderView from '@/components/MaterialOrderView'
import DatePickerPlus from '@/components/tables/DatePickerPlus'
import mu from '@/js/mu'

export default {
  name: 'Shipments',
  components: { MaterialOrderView, QuoteEdit, DatePickerPlus },
  data() {
    return {
      tallyOrders: [],
      shipments: [],
      loading: true,
      shipmentDetailsVisible: false,
      selectedShipment: {},
      overlayComponent: null,
      dialogTitle: null,
      dialogVisible: false,
      scheduleDialog: false,
      taskSchedule: null,
      pauseShipmentDialog: false,
      deleteShipmentDialog: false,
      dispatchedDialog: false,
      editDblCheckDialog: false,
      editNotesDialog: false,
      selectedQuote: null,
      selectedVersionIndex: null,
      notesSubmitting: false,
      submitting: false,
      tmpValue: null,
      toolpathMode: false,
      pauseForm: {
        why: null,
        changesCheck: null,
        changes: null
      },
      editPauseLogDialog: false,
      deletePauseLogDialog: false,
      deletingPauseLog: false,
      selectedPauseLog: {},
      selectedPauseLogKey: null,
      headers: [
        {
          text: 'Order #',
          sortable: false,
          width: 100,
          value: 'orderNumber'
        },
        {
          text: 'Customer',
          sortable: false,
          value: 'customerEmail'
        },
        {
          text: 'Items',
          sortable: false,
          value: 'lineItems'
        },
        {
          text: 'Material Due In',
          sortable: true,
          value: 'materialOrdered'
        },
        {
          text: 'Toolpathed',
          sortable: true,
          value: 'toolpathed'
        },
        {
          text: 'Payment Date',
          sortable: true,
          value: 'paymentDate'
        },
        {
          text: 'Sprayer Date',
          sortable: true,
          value: 'sprayerDate'
        },
        {
          text: 'Target Delivery',
          sortable: true,
          value: 'deliveryDate'
        },
        {
          text: 'Scheduled Delivery',
          sortable: true,
          value: 'dateScheduled'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'right',
          width: 160,
          value: null
        }
      ],
      tableSearch: ''
    }
  },
  methods: {
    changed(shipment, attribute) {
      // update in firebase
      this.$db
        .collection('shipments')
        .doc(shipment.id)
        .update({
          [attribute]: shipment[attribute]
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Shipment Updated 🙌'
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        v =>
          v &&
          v
            .toString()
            .toLowerCase()
            .includes(search)
      )
    },
    scheduleDialogClose() {
      this.taskSchedule = null
    },
    formatLineItems(a) {
      var s = ''
      for (var i = 0; i < a.length; i++) {
        s += a[i]
        if (i < a.length - 1) s += ', '
      }
      return s
    },
    viewQuote(shipment) {
      this.dialogVisible = true
      this.selectedShipment = shipment
      this.dialogTitle = shipment.customerEmail
      this.overlayComponent = 'QuoteEdit'
    },
    materialView(shipment) {
      this.dialogVisible = true
      this.selectedShipment = shipment
      this.dialogTitle = 'Material Order List'
      this.overlayComponent = 'MaterialOrderView'
    },
    sprayerView(shipment) {
      var routeData = this.$router.resolve({
        name: 'sprayerView',
        params: { shipmentID: shipment.id }
      })
      window.open(routeData.href, '_blank')
    },
    packingView(shipment) {
      var routeData = this.$router.resolve({
        name: 'packingView',
        params: { shipmentID: shipment.id }
      })
      window.open(routeData.href, '_blank')
    },
    editNotes(shipment) {
      this.selectedShipment = shipment
      this.editNotesDialog = true

      this.$db
        .collection('quotes')
        .doc(shipment.quoteID)
        .get()
        .then(quoteRef => {
          this.selectedQuote = quoteRef.data()

          for (var i = 0; i < this.selectedQuote.versions.length; i++) {
            if (this.selectedQuote.versions[i].id == shipment.versionID) {
              this.selectedVersionIndex = i
            }
          }
        })
    },
    updateNotes() {
      this.editNotesDialog = false
      this.notesSubmitting = true

      this.$db
        .collection('quotes')
        .doc(this.selectedShipment.quoteID)
        .update({
          versions: this.selectedQuote.versions
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Notes Updated 🙌'
          })
          this.notesSubmitting = false
          this.selectedQuote = null
          this.selectedShipment = {}
          this.selectedVersionIndex = null
        })
    },
    schedule(shipment) {
      this.selectedShipment = shipment
      this.scheduleDialog = true
      this.$db
        .collection('tasks')
        .where('shipmentID', '==', shipment.id)
        .get()
        .then(tasks => {
          this.taskSchedule = tasks.docs.sort((a, b) => {
            return a.data().dateScheduled > b.data().dateScheduled ? 1 : -1
          })
        })
    },
    toggleShipmentPause(shipment) {
      let pauseLog
      let updateObj = {
        paused: !this.selectedShipment.paused
      }
      if (!this.selectedShipment.paused) {
        // paused state
        if (!this.$refs.pauseForm.validate()) {
          return
        } else {
          if (!this.selectedShipment.pauseLog) {
            pauseLog = [
              {
                why: this.pauseForm.why,
                changes: this.pauseForm.changes ? this.pauseForm.changes : 'None',
                date: this.$firebase.firestore.Timestamp.now(),
                author: this.$firebase.auth().currentUser.email
              }
            ]
          } else {
            pauseLog = this.$firebase.firestore.FieldValue.arrayUnion({
              why: this.pauseForm.why,
              changes: this.pauseForm.changes ? this.pauseForm.changes : 'None',
              date: this.$firebase.firestore.Timestamp.now(),
              author: this.$firebase.auth().currentUser.email
            })
          }

          // save change statement (will always be latest pause log) in selectedQuote
          this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = this.pauseForm.changes
            ? this.pauseForm.changes
            : ''

          // then update to quotes db
          this.$db
            .collection('quotes')
            .doc(this.selectedShipment.quoteID)
            .update({
              versions: this.selectedQuote.versions
            })
            .then(() => {
              // this.$store.commit('openSnackbar', {
              //   snackbarText: '🗒️ Changes added as Prod Notes!'
              // })
              this.selectedQuote = null
              this.selectedVersionIndex = null
            })
        }
        updateObj.pauseLog = pauseLog
      }

      this.pauseShipmentDialog = false

      this.pauseForm.why = null
      this.pauseForm.changes = null
      if (this.pauseForm.changesCheck) this.pauseForm.changesCheck = null

      this.$db
        .collection('shipments')
        .doc(shipment.id)
        .update(updateObj)
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Shipment Updated' })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    findQuoteFromShipment() {
      this.$db
        .collection('quotes')
        .doc(this.selectedShipment.quoteID)
        .get()
        .then(quoteRef => {
          this.selectedQuote = quoteRef.data()

          for (var i = 0; i < this.selectedQuote.versions.length; i++) {
            if (this.selectedQuote.versions[i].id == this.selectedShipment.versionID) {
              this.selectedVersionIndex = i
            }
          }
        })
    },
    updatePauseLog(key) {
      let updateObj = {
        paused: this.selectedShipment.paused
      }

      // include new author and timestamp if you want to show updated time and author for pause log??
      // updateObj.pauseLog.date = this.$firebase.firestore.Timestamp.now()
      // updateObj.pauseLog.author = this.$firebase.auth().currentUser.email
      updateObj.pauseLog = this.selectedShipment.pauseLog

      this.editPauseLogDialog = false
      this.selectedPauseLog = {}

      this.$db
        .collection('shipments')
        .doc(this.selectedShipment.id)
        .update(updateObj)
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Pause Log Updated' })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })

      // Trying to find quote with same quote ID from selected shipment
      if (key && key == this.selectedShipment.pauseLog.length - 1) {
        // add latest pausedLog to selectedQuote as changeStatement
        this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = this.selectedShipment.pauseLog[
          key
        ].changes

        // then update to quotes db to generate prod notes
        this.$db
          .collection('quotes')
          .doc(this.selectedShipment.quoteID)
          .update({
            versions: this.selectedQuote.versions
          })
          .then(() => {
            this.$store.commit('openSnackbar', {
              snackbarText: '🗒️ Changes added as Prod Notes!'
            })
            this.selectedQuote = null
            this.selectedVersionIndex = null
          })
      }
    },
    deletePauseLog(key) {
      this.deletePauseLogDialog = false
      this.deletingPauseLog = true

      let generateProdNote
      if (key == this.selectedShipment.pauseLog.length - 1 && key != 0) generateProdNote = true

      let clearChangeStatement
      if (key == 0) clearChangeStatement = true

      // then will delete that log!
      this.selectedShipment.pauseLog.splice(key, 1)

      if (generateProdNote) {
        let latest = this.selectedShipment.pauseLog.length - 1
        // add latest pausedLog to selectedQuote as changeStatement
        this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = this.selectedShipment.pauseLog[
          latest
        ].changes
      } else if (clearChangeStatement) {
        this.selectedQuote.versions[this.selectedVersionIndex].changeStatement = ''
      }

      // then update to quotes db to generate prod notes
      this.$db
        .collection('quotes')
        .doc(this.selectedShipment.quoteID)
        .update({
          versions: this.selectedQuote.versions
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: '🗒️ Changes added as Prod Notes!'
          })
        })

      let updateObj = {
        paused: this.selectedShipment.paused
      }
      updateObj.pauseLog = this.selectedShipment.pauseLog
      this.$db
        .collection('shipments')
        .doc(this.selectedShipment.id)
        .update(updateObj)
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Pause Log Updated' })
          this.deletingPauseLog = false
          this.selectedQuote = null
          this.selectedVersionIndex = null
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    deleteShipment(shipment) {
      this.deleteShipmentDialog = false
      this.$db
        .collection('shipments')
        .doc(shipment.id)
        .delete()
        .then(() => {
          this.$store.commit('openSnackbar', { snackbarText: 'Order Deleted' })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    markShipmentAsDispatched(shipment) {
      this.dispatchedDialog = false
      this.$db
        .collection('shipments')
        .doc(shipment.id)
        .update({
          shipped: true
        })
        .then(() => {
          this.$store.commit('openSnackbar', {
            snackbarText: 'Mark Shipment as Dispatched'
          })
        })
        .catch(err => {
          this.$store.commit('openSnackbar', { snackbarText: 'Error: ' + err })
        })
    },
    async updateTasks(shipment) {
      const quote = await this.$db
        .collection('quotes')
        .doc(shipment.quoteID)
        .get()

      let quoteData = quote.data()

      const version = quoteData.versions.find(v => v.id === shipment.versionID)
      version.mu = mu.get(version)

      // update quote with quoteData object
      await this.$db
        .collection('quotes')
        .doc(shipment.quoteID)
        .update(quoteData)

      // update tasks
      const updateTasksForOrderFunc = this.$firebase.functions().httpsCallable('updateTasksForOrder')
      await updateTasksForOrderFunc(shipment.orderID)
      this.scheduleDialog = false
    },
    toggleToolpathMode() {
      this.toolpathMode = !this.toolpathMode
      // console.log(this.toolpathMode)
      // this.sortConfig.sortBy = this.toolpathMode ? 'deliveryDate' : 'dateScheduled'
    },
    shipmentStartsIn(shipment) {
      return this.$utils.momentFromTimestamp(shipment.deliveryDate).diff(this.$moment(), 'days')
    },
    printableSVG(shipment) {
      var routeData = this.$router.resolve({
        name: 'customerSVG',
        params: {
          quoteID: shipment.quoteID,
          versionID: shipment.versionID
        }
      })
      window.open(routeData.href, '_blank')
    }
  },
  async created() {
    await this.$bind(
      'tallyOrders',
      this.$db
        .collection('orders')
        .where('foc', '==', false)
        .where('dispatched', '==', false)
      // .where('numPayments', '>', 0)
    )
    await this.$bind(
      'shipments',
      this.$db
        .collection('shipments')
        .where('shipped', '==', false)
        .where('readyForDispatch', '==', false)
    )

    this.loading = false
  },
  computed: {
    ukOrderTally() {
      let totalSum = 0
      for (const order of this.tallyOrders) {
        totalSum += order?.total
      }
      return 'Orders Totals = £' + this.$numeral(totalSum).format('0,0.00')
    }
  }
}
</script>
<style lang="scss" scoped>
#production-table {
  width: 100%;

  #tasks-dialog {
    .task {
      margin: 10px 0;
      padding: 10px 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      background: #eee;
      .details {
        text-transform: capitalize;
      }
    }
  }
  .text-truncate {
    max-width: 150px;
  }
}
.pause-log-item {
  padding: 8px;
  border: solid 1px grey;
}
</style>
