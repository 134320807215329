<template>
  <div id="customer-cad">
    <v-overlay v-if="loading" color="primary" :opacity="0.9" class="text-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <!-- Customer CAD Submenu -->
    <div id="view-sub-nav" v-if="!customerView">
      <v-btn class="mr-2" depressed outlined color="primary" @click="regenerateCad">Regenerate</v-btn>
      <v-btn class="mx-2" depressed outlined color="primary" @click="centerView">Center</v-btn>
      <v-spacer></v-spacer>
      <transition name="fade">
        <div v-if="version.customerVisualID" class="saved-options">
          <div id="publish-switch">
            <span>Shared with customer</span>
            <v-switch v-model="version.customerCadShared" hide-details light></v-switch>
          </div>
          <v-btn class="mx-2" depressed outlined color="primary" @click="$emit('printableSVG')"
            >Open Printable Version</v-btn
          >
        </div>
      </transition>
      <v-btn
        class="ml-2"
        depressed
        outlined
        :color="unsavedChanges ? 'white' : 'primary'"
        :style="unsavedChanges ? 'background-color:red;' : ''"
        @click="
          () => {
            userRequestedSave = true
            save()
          }
        "
        >Save Layout</v-btn
      >
    </div>

    <div class="app" :class="{ panning: activeKey == 'space' }">
      <canvas id="paper" resize="true" :style="{ height: canvasHeight + 'px' }"></canvas>

      <div class="controls">
        <v-switch
          class="mr-5 mt-0"
          size="28"
          color="black"
          :label="showingDims ? 'Hide Dimensions' : 'Show Dimensions'"
          @click.native="
            () => {
              showingDims = !showingDims
              showDims()
            }
          "
        ></v-switch>
        <div class="btn">
          <v-icon color="black" size="30" @click.native="zoomOut">mdi-magnify-minus-outline</v-icon>
        </div>
        <div class="btn ml-1">
          <v-icon color="black" size="30" @click.native="zoomIn">mdi-magnify-plus-outline</v-icon>
        </div>
        <div class="btn ml-2">
          <v-icon color="black" size="28" @click.native="centerView">mdi-image-filter-center-focus</v-icon>
        </div>
        <div v-if="!customerView" class="btn ml-3">
          <v-icon color="black" size="28" @click.native="bringToFront">mdi-arrange-bring-to-front</v-icon>
        </div>
        <div v-if="!customerView" class="btn ml-4">
          <v-icon color="black" size="28" @click.native="showGrain">mdi-waves</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paper from 'paper'
import utils from '@/js/utils'
import cadUtils from '@/js/cad/CadUtils'
import Front from '@/js/cad/customer/Front'
import TwoPartCorner from '@/js/cad/customer/specials/TwoPartCorner'
import CoverPanel from '@/js/cad/customer/CoverPanel'
import SpacerPanel from '@/js/cad/customer/SpacerPanel'
import Worktop from '@/js/cad/customer/Worktop'
import Urtil from '@/js/cad/customer/products/Urtil'
import Pantry from '@/js/cad/customer/products/Pantry'

export default {
  name: 'CustomerCad',
  props: ['version', 'customerView', 'quote', 'disableControls', 'inline'],
  data() {
    return {
      canvasHeight: null,
      viewPadding: 20,
      selectedItems: [],
      activeKey: null,
      materialLookup: null,
      handleLookup: null,
      frontsData: null,
      coverPanelsData: null,
      dragStartData: null,
      marqueeOrigin: null,
      marqueeRect: null,
      items: [],
      tool: null,
      snappingThreshold: 5,
      unsavedChanges: false,
      autoSaveFrequency: 2000,
      userRequestedSave: false,
      itemsDragged: false,
      loading: false,
      loadingMessage: '',
      showingDims: false,
      storedDoorColours: {},
      invisipullExtension: 30
    }
  },
  created() {
    this.materialLookup = cadUtils.constructMaterialLookup(this.version)
    this.handleLookup = cadUtils.constructHandleLookup(this.version, this.materialLookup)
    this.frontsData = cadUtils.constructFrontsArray(this.version, this.materialLookup, this.handleLookup)
    this.coverPanelsData = cadUtils.constructCoverPanelArray(this.version, this.materialLookup)

    if (!this.disableControls) {
      window.addEventListener('resize', this.adjustCanvasHeight)
      window.addEventListener('wheel', this.scaleView)
      this.adjustCanvasHeight()
    }
  },
  beforeDestroy() {
    this.tool.remove()
    this.tool = null
    if (!this.disableControls) {
      window.removeEventListener('wheel', this.scaleView)
      window.removeEventListener('resize', this.adjustCanvasHeight)
    }
  },
  computed: {
    headerHeight() {
      return this.customerView ? 80 : 184
    }
  },
  async mounted() {
    paper.setup(document.getElementById('paper'))
    paper.settings.handleSize = 0
    this.tool = new paper.Tool()

    /********** LISTENERS **********/

    if (!this.customerView) {
      this.tool.onMouseDown = e => {
        if (e.item == null && this.activeKey != 'space') {
          this.deselectAndSave()
          this.marqueeOrigin = e.point
        }
      }
      this.tool.onMouseDrag = e => {
        if (this.marqueeOrigin) {
          if (this.marqueeRect) {
            this.marqueeRect.remove()
          }
          let width = e.point.x - this.marqueeOrigin.x
          let height = e.point.y - this.marqueeOrigin.y
          this.marqueeRect = new paper.Path.Rectangle(
            new paper.Rectangle(this.marqueeOrigin, new paper.Size(width, height))
          )
          this.marqueeRect.strokeColor = 'red'
          this.marqueeRect.dashArray = [5, 5]

          // test for selected items
          this.selectedItems = []

          for (let i = 0; i < this.items.length; i++) {
            if (
              this.marqueeRect.intersects(this.items[i].item) ||
              this.items[i].item.isInside(this.marqueeRect.bounds)
            ) {
              this.selectedItems.push(this.items[i].item)
            }
          }
        } else if (this.activeKey == 'space') {
          paper.project.activeLayer.translate(e.delta.x, e.delta.y)
        }
      }
      this.tool.onMouseUp = () => {
        if (this.marqueeOrigin) {
          this.marqueeOrigin = null
        }
        if (this.marqueeRect) {
          this.marqueeRect.remove()
        }
      }
      this.tool.onKeyDown = e => {
        this.activeKey = e.key
      }
      this.tool.onKeyUp = e => {
        this.activeKey = null
        // const dist = e.event.getModifierState('Shift') ? 10 : 1 // getModifierState is not a function error
        const dist = 1 // 1 pixel bump
        switch (e.key) {
          case '-':
            this.zoomOut()
            break
          case '=':
            this.zoomIn()
            break
          case 'c':
            this.centerView()
            break
          case 'right':
            if (this.selectedItems.length > 0) {
              for (let item of this.selectedItems) {
                item.translate(dist, 0)
              }
              this.unsavedChanges = true
            }
            break
          case 'left':
            if (this.selectedItems.length > 0) {
              for (let item of this.selectedItems) {
                item.translate(-dist, 0)
              }
              this.unsavedChanges = true
            }
            break
          case 'up':
            if (this.selectedItems.length > 0) {
              for (let item of this.selectedItems) {
                item.translate(0, -dist)
              }
              this.unsavedChanges = true
            }
            break
          case 'down':
            if (this.selectedItems.length > 0) {
              for (let item of this.selectedItems) {
                item.translate(0, dist)
              }
              this.unsavedChanges = true
            }
            break
          case 'escape':
            this.deselectAndSave()
            break
          case 'z':
            paper.project.activeLayer.fitBounds(
              new paper.Rectangle(
                new paper.Point(this.viewPadding, this.viewPadding),
                new paper.Size(
                  paper.view.bounds.width - this.viewPadding * 2,
                  paper.view.bounds.height - this.viewPadding * 2
                )
              )
            )
            break
        }
      }
    }

    if (this.customerView) {
      this.tool.onMouseDown = e => {
        if (e.item == null && this.activeKey != 'space') {
          this.deselectOnly()
          this.marqueeOrigin = e.point
        }
        if (e.item) {
          this.selectedItems.find(item => item == e.item)
            ? this.selectedItems.splice(
                this.selectedItems.findIndex(item => item == e.item),
                1
              )
            : this.selectedItems.push(e.item)
        }
      }
      this.tool.onMouseDrag = e => {
        if (this.marqueeOrigin) {
          if (this.marqueeRect) {
            this.marqueeRect.remove()
          }
          let width = e.point.x - this.marqueeOrigin.x
          let height = e.point.y - this.marqueeOrigin.y
          this.marqueeRect = new paper.Path.Rectangle(
            new paper.Rectangle(this.marqueeOrigin, new paper.Size(width, height))
          )
          this.marqueeRect.strokeColor = 'red'
          this.marqueeRect.dashArray = [5, 5]

          // test for selected items
          this.selectedItems = []

          for (let i = 0; i < this.items.length; i++) {
            if (
              this.marqueeRect.intersects(this.items[i].item) ||
              this.items[i].item.isInside(this.marqueeRect.bounds)
            ) {
              this.selectedItems.push(this.items[i].item)
            }
          }
        }
      }
      this.tool.onMouseUp = () => {
        if (this.marqueeOrigin) {
          this.marqueeOrigin = null
        }
        if (this.marqueeRect) {
          this.marqueeRect.remove()
        }
      }
    }

    // if saved items load them in
    try {
      if (this.version.customerVisualID) {
        this.loading = true

        const visualSnap = await this.$db
          .collection('visuals')
          .doc(this.version.customerVisualID)
          .get()
        paper.project.importJSON(visualSnap.data().json)

        this.loading = false

        let primary = paper.project.layers.saved.clone({
          insert: false,
          deep: false
        })
        primary.name = 'primary'
        paper.project.addLayer(primary)
        paper.project.layers.primary.activate()

        this.generateItems()
        const paperProjPrimary = paper.project.layers.primary
        const paperProjSaved = paper.project.layers.saved

        let scale = this.calculateScale(paper.project.layers.saved.children[0])
        for (let i = 0; i < paperProjPrimary.children.length; i++) {
          paperProjPrimary.children[i].scale(scale)
          let primaryUid = paperProjPrimary.children[i].name
          if (paperProjSaved.children[primaryUid]) {
            paperProjPrimary.children[i].position.x = paperProjSaved.children[primaryUid].position.x
            paperProjPrimary.children[i].position.y = paperProjSaved.children[primaryUid].position.y
          } else {
            // just shove them to the left of the first item
            paperProjPrimary.children[i].position.x =
              paperProjSaved.children[0].position.x - paperProjSaved.children[0].bounds.width * 2
            paperProjPrimary.children[i].position.y = paperProjSaved.children[0].position.y
          }
        }

        // sort the array paper.project.layers.primary.children so it matches the order of paper.project.layers.saved.children
        let sorted = []
        let newItems = []
        for (let j = 0; j < paperProjSaved.children.length; j++) {
          let savedUid = paperProjSaved.children[j].name
          for (let k = 0; k < paperProjPrimary.children.length; k++) {
            if (paperProjPrimary.children[k].name == savedUid) {
              sorted.push(paperProjPrimary.children[k])
            } else {
              newItems.push(paperProjPrimary.children[k])
            }
          }
        }
        paperProjPrimary.children = newItems.concat(sorted)

        paper.project.layers.saved.remove()
      } else {
        paper.project.activeLayer.name = 'primary'
        this.generateItems()
      }
    } catch (e) {
      console.log('Error', e)
    }
    this.centerView()
    paper.view.draw()
    // this.loading = false
  },
  watch: {
    selectedItems(to) {
      for (let i = 0; i < this.items.length; i++) {
        let selection = this.items[i].item

        // store original door colour
        if (selection.children.doors?.children) {
          if (!this.storedDoorColours[i]) {
            this.storedDoorColours[i] = []
            selection.children.doors.children.forEach(door => {
              if (door.style.fillColor?.alpha > 0) {
                this.storedDoorColours[i].push(door.style.fillColor)
              }
            })
          }
        }

        if (to.indexOf(selection) >= 0) {
          selection.selected = true
          let isBackDark = utils.isColourDark(selection.children.back?.style.fillColor.toCSS())
          let colorCode
          let foundBack = selection.children.findIndex(obj => obj.name == 'pantryBack')
          if (foundBack > -1) {
            colorCode = selection.children[foundBack]?.children[0]?.fillColor?._canvasStyle
          } else if (selection.children.outline?.children) {
            colorCode = selection.children.outline.children[0]?.fillColor?._canvasStyle
          } else if (selection.children.outline?.style) {
            colorCode = selection.children.outline.style?._values.fillColor?._canvasStyle
          } else {
            colorCode = 'rgb(0,0,0)' // default to black
          }

          // display dimension label on selected item
          if (selection.children.dimension) {
            // console.log(colorCode, utils.isColourDark(colorCode))
            selection.children.dimension.style.fillColor = utils.isColourDark(colorCode) ? 'white' : 'black'
          }

          // display joined icon on selected item
          if (selection.children.joining) {
            selection.children.joining.style.strokeColor = utils.isColourDark(colorCode) ? 'white' : 'black'
          }

          // display adjust icon on selected item
          if (selection.children.label?.children?.adjIcon) {
            selection.children.label.children.adjIcon.children.forEach(
              adjIcon => (adjIcon.style.fillColor = isBackDark ? 'white' : 'black')
            )
          }

          // hide doors to reveal the inside of Urtil
          if (selection.children.doors) {
            selection.children.doors.children.forEach(door => {
              door.style.fillColor = 'transparent'
            })
          }
        } else {
          selection.selected = false

          // hide dimension label
          if (selection.children?.dimension) {
            selection.children.dimension.style.fillColor = 'transparent'
          }

          // hide joined icon
          if (selection.children?.joining) {
            selection.children.joining.style.strokeColor = 'transparent'
          }

          // hide adjust icon
          if (selection.children?.label?.children?.adjIcon) {
            selection.children.label.children.adjIcon.children.forEach(
              adjIcon => (adjIcon.style.fillColor = 'transparent')
            )
          }

          // display doors
          if (selection.children?.doors) {
            selection.children.doors.children.forEach((door, index) => {
              door.style.fillColor = this.storedDoorColours[i][index]
            })
          }
        }
      }
    }
  },
  methods: {
    bringToFront() {
      const primaryLayerChildren = paper.project.layers.primary.children
      this.selectedItems.forEach(selection => {
        primaryLayerChildren.splice(primaryLayerChildren.indexOf(selection), 1)
        primaryLayerChildren.splice(primaryLayerChildren.length, 0, selection)
      })
      this.unsavedChanges = true
      this.items.forEach(item => (item.item.selected = false))
      this.deselectAndSave()
    },
    showGrain() {
      this.items.forEach(item => {
        let grainIcon = item.item.children?.grain

        if (grainIcon) {
          if (grainIcon.style.fillColor.alpha > 0) {
            grainIcon.style.fillColor = 'transparent'
          } else {
            grainIcon.style.fillColor = 'black'
          }
        }
      })
    },
    showDims() {
      if (this.showingDims) {
        this.items.forEach(item => {
          this.selectedItems = []

          for (let i = 0; i < this.items.length; i++) {
            this.selectedItems.push(this.items[i].item)
          }
        })
      } else {
        this.deselectOnly()
      }
    },
    deselectOnly() {
      this.selectedItems = []
    },
    deselectAndSave() {
      this.selectedItems = []
      if (this.unsavedChanges) {
        this.save()
      }
    },
    calculateScale(item) {
      if (item.data.originalBounds) {
        return item.bounds.width / item.data.originalBounds.width
      } else {
        return 1
      }
    },
    adjustCanvasHeight() {
      this.canvasHeight = window.innerHeight - this.headerHeight
    },
    zoomIn() {
      paper.project.activeLayer.scale(1.1)
    },
    zoomOut() {
      paper.project.activeLayer.scale(0.9)
    },
    centerView() {
      paper.project.activeLayer.fitBounds(
        new paper.Rectangle(
          new paper.Point(this.viewPadding, this.viewPadding),
          new paper.Size(
            paper.view.bounds.width - this.viewPadding * 2,
            paper.view.bounds.height - this.viewPadding * 2
          )
        )
      )
    },
    scaleView(e) {
      // console.log(e)
      let sensitivity = 0.1
      let scale = 1 + Math.sign(e.deltaY) * sensitivity
      paper.project.activeLayer.scale(scale, new paper.Point(e.layerX, e.layerY))
    },
    generateItems() {
      this.items = []
      // order matters for layering
      try {
        this.createFronts()
        this.createCoverPanels()
        this.createSpacerPanels()
        this.createWorktopSections()
        this.createUrtils()
        this.createPantries()

        return true
      } catch (error) {
        console.log('Error', error)
        return false
      }
    },
    createFronts() {
      let region = this.quote.region
      let xPos = 0
      let yPos = 0

      for (let i = 0; i < this.frontsData.length; i++) {
        let breakLoop = false

        let frontData = this.frontsData[i]
        let prevFrontData = this.frontsData[i - 1]
        let omitLabel = false

        let cornerDoorTotalSpace
        if (prevFrontData && prevFrontData.cabinet == 'w') {
          cornerDoorTotalSpace = region === 'uk' ? 220 : 229
        } else if (prevFrontData && prevFrontData.cabinet != 'w') {
          cornerDoorTotalSpace = region === 'uk' ? 140 : 121
        }

        if (i > 0) {
          // adjust xy positioning
          if (frontData.itemNum == prevFrontData.itemNum) {
            omitLabel = true
            if (prevFrontData.type == 'lhd' && frontData.type == 'rhd') {
              xPos += prevFrontData.w
              yPos = 0
              omitLabel = false
            }
          } else if (frontData.itemNum != prevFrontData.itemNum) {
            if (prevFrontData.special == '2part') {
              xPos += region === 'us' ? 676 : 508
            } else if (prevFrontData.special == 'cnr') {
              xPos += frontData.w + cornerDoorTotalSpace
            } else {
              xPos += prevFrontData.w
            }
            yPos = 0
          }
        }

        for (let j = 0; j < frontData.qty; j++) {
          let f
          if (j > 0) omitLabel = true

          let cornerFrontFillerData

          if (frontData.special == 'cnr') {
            cornerFrontFillerData = {
              cornerFrontData: {
                w: frontData.w,
                h: frontData.h,
                qty: 1,
                handle: frontData.handle,
                material: frontData.material,
                special: frontData.special,
                cabinet: frontData.cabinet,
                type: frontData.type,
                uid: frontData.uid,
                itemNum: frontData.itemNum,
                joiningDouble: null,
                joiningPos: null
              },
              narrowFillerData: {
                w:
                  region == 'uk' && frontData.cabinet == 'w'
                    ? 100
                    : region == 'uk' && frontData.cabinet != 'w'
                    ? 60
                    : region == 'us' && frontData.cabinet == 'w'
                    ? 102
                    : 51,
                h:
                  frontData.handle.value == 'invisipull' && frontData.cabinet == 'w'
                    ? frontData.h + this.invisipullExtension
                    : frontData.h,
                type: 'filler',
                qty: 1,
                uid: frontData.uid + '-nf',
                itemNum: frontData.itemNum,
                material: frontData.material,
                opposingGrain: null,
                doubleSided: null,
                lighting: null,
                lightingOffset: null,
                lightingRef: null,
                vent: null,
                ventOffset: null,
                ventRef: null
              },
              wideFillerData: {
                w:
                  region == 'uk' && frontData.cabinet == 'w'
                    ? 120
                    : region == 'uk' && frontData.cabinet != 'w'
                    ? 80
                    : region == 'us' && frontData.cabinet == 'w'
                    ? 127
                    : 70,
                h:
                  frontData.handle.value == 'invisipull' && frontData.cabinet == 'w'
                    ? frontData.h + this.invisipullExtension
                    : frontData.h,
                type: 'filler',
                qty: 1,
                uid: frontData.uid + '-wf',
                itemNum: frontData.itemNum,
                material: frontData.material,
                opposingGrain: null,
                doubleSided: null,
                lighting: null,
                lightingOffset: null,
                lightingRef: null,
                vent: null,
                ventOffset: null,
                ventRef: null
              }
            }
          }

          if (!frontData.special) {
            f = new Front(frontData.uid + '-' + j, frontData, omitLabel, region, this.version.grainDirection)
          } else {
            switch (frontData.special) {
              case '2part':
                if (j > 0) {
                  breakLoop = true
                  break
                } else {
                  f = new TwoPartCorner(frontData.uid + '-' + j, frontData, region, this.version.grainDirection)
                  break
                }
              case 'cnr':
                if (j == 0) {
                  if (frontData.type == 'rhd') {
                    f = new Front(
                      frontData.uid + '-' + j,
                      cornerFrontFillerData.cornerFrontData,
                      (omitLabel = false),
                      region,
                      this.version.grainDirection
                    )
                  } else if (frontData.type == 'lhd') {
                    f = new CoverPanel(
                      frontData.uid + '-' + j,
                      cornerFrontFillerData.wideFillerData,
                      region,
                      this.version.grainDirection
                    )
                  }
                  break
                } else if (j == 1) {
                  f = new CoverPanel(
                    frontData.uid + '-' + j,
                    cornerFrontFillerData.narrowFillerData,
                    region,
                    this.version.grainDirection
                  )
                  break
                } else if (j == 2) {
                  if (frontData.type == 'rhd') {
                    f = new CoverPanel(
                      frontData.uid + '-' + j,
                      cornerFrontFillerData.wideFillerData,
                      region,
                      this.version.grainDirection
                    )
                  } else if (frontData.type == 'lhd') {
                    f = new Front(
                      frontData.uid + '-' + j,
                      cornerFrontFillerData.cornerFrontData,
                      (omitLabel = false),
                      region,
                      this.version.grainDirection
                    )
                  }
                  break
                }
              case 'a':
                // nothing special for now...
                f = new Front(frontData.uid + '-' + j, frontData, omitLabel, region, this.version.grainDirection)
                break
              default:
                f = new Front(frontData.uid + '-' + j, frontData, omitLabel, region, this.version.grainDirection)
            }
          }

          if (breakLoop) break

          if (
            !this.version.customerVisualID ||
            (this.version.customerVisualID && !paper.project.layers[0].children[frontData.uid])
          ) {
            if (frontData.special == 'cnr') {
              let narrowFillerXPos
              let wideFillerXPos

              if (frontData.cabinet && frontData.cabinet == 'w') {
                narrowFillerXPos = region == 'uk' ? 100 : 102
                wideFillerXPos = region == 'uk' ? 120 : 127
              } else if (frontData.cabinet && frontData.cabinet != 'w') {
                narrowFillerXPos = region == 'uk' ? 60 : 51
                wideFillerXPos = region == 'uk' ? 80 : 70
              }

              switch (j) {
                case 0:
                  f.item.translate(new paper.Point(xPos, yPos - frontData.h))
                  break
                case 1:
                  f.item.translate(new paper.Point(-narrowFillerXPos, yPos))
                case 2:
                  if (frontData.type == 'rhd') {
                    f.item.translate(new paper.Point(xPos + frontData.w + narrowFillerXPos, yPos - frontData.h))
                  } else if (frontData.type == 'lhd') {
                    f.item.translate(new paper.Point(xPos + wideFillerXPos + narrowFillerXPos, yPos - frontData.h))
                  }
                  break
              }
            } else {
              f.item.translate(new paper.Point(xPos, yPos - frontData.h))
              yPos -= frontData.h
            }
          }

          // add interaction
          if (!this.customerView) {
            f.item.onMouseDown = this.selectItem
            f.item.onMouseDrag = this.dragSelected
            f.item.onMouseUp = this.itemMouseUp
          }

          // clean up
          this.items.push(f)
        }
      }
    },
    createCoverPanels() {
      let xPos = 100
      let yPos = 500

      for (let i = 0; i < this.coverPanelsData.length; i++) {
        for (let j = 0; j < this.coverPanelsData[i].qty; j++) {
          let cp = new CoverPanel(
            this.coverPanelsData[i].uid + '-' + j,
            this.coverPanelsData[i],
            this.quote.region,
            this.version.grainDirection
          )

          // position
          if (
            !this.version.customerVisualID ||
            (this.version.customerVisualID && !paper.project.layers[0].children[this.coverPanelsData.uid])
          ) {
            cp.item.translate(new paper.Point(xPos, yPos))
            xPos += cp.item.bounds.width + 50
          }

          // add interaction
          if (!this.customerView) {
            cp.item.onMouseDown = this.selectItem
            cp.item.onMouseDrag = this.dragSelected
            cp.item.onMouseUp = this.itemMouseUp
          }

          // clean up
          this.items.push(cp)
        }
      }
    },
    createSpacerPanels() {
      let xPos = -100
      let yPos = 500

      for (let i = 0; i < this.version.spacerPanels.length; i++) {
        for (let j = 0; j < this.version.spacerPanels[i].qty; j++) {
          let sp = new SpacerPanel(
            this.version.spacerPanels[i].uid + '-' + j,
            this.version.spacerPanels[i],
            this.quote.region
          )
          if (!this.version.customerVisualID && !paper.project.layers[0].children[this.version.spacerPanels[i].uid]) {
            sp.item.translate(new paper.Point(xPos, yPos))
          }
          this.items.push(sp)
          xPos -= sp.item.bounds.width + 50

          // add interaction
          if (!this.customerView) {
            sp.item.onMouseDown = this.selectItem
            sp.item.onMouseDrag = this.dragSelected
            sp.item.onMouseUp = this.itemMouseUp
          }
        }
      }
    },
    createWorktopSections() {
      let xPos = -200
      let yPos = 0

      for (let i = 0; i < this.version.worktops.length; i++) {
        let worktop = this.version.worktops[i]
        for (let j = 0; j < worktop.sections.length; j++) {
          let section = new Worktop(worktop, worktop.sections[j], this.quote.region)
          if (!this.version.customerVisualID && !paper.project.layers[0].children[worktop.sections[j].uid]) {
            section.item.translate(new paper.Point(xPos - worktop.sections[j].l, yPos))
          }
          this.items.push(section)
          yPos -= 50

          // add interaction
          if (!this.customerView) {
            section.item.onMouseDown = this.selectItem
            section.item.onMouseDrag = this.dragSelected
            section.item.onMouseUp = this.itemMouseUp
          }
        }
      }
    },
    createUrtils() {
      if (this.version.urtils && this.version.urtils.length > 0) {
        for (let i = 0; i < this.version.urtils.length; i++) {
          let urtil = new Urtil(
            this.version.urtils[i].uid,
            this.version.urtils[i],
            this.materialLookup[this.version.urtils[i].back],
            this.materialLookup[this.version.urtils[i].carcass],
            this.materialLookup[this.version.urtils[i].material1],
            this.materialLookup[this.version.urtils[i].material2],
            this.version.grainDirection
          )

          this.items.push(urtil)

          // add interaction
          if (!this.customerView) {
            urtil.item.onMouseDown = this.selectItem
            urtil.item.onMouseDrag = this.dragSelected
            urtil.item.onMouseUp = this.itemMouseUp
          }
        }
      }
    },
    createPantries() {
      if (this.version.pantries && this.version.pantries.length > 0) {
        for (let i = 0; i < this.version.pantries.length; i++) {
          let pantry = new Pantry(
            this.version.pantries[i].uid,
            this.version.pantries[i],
            this.materialLookup[this.version.pantries[i].carcass],
            this.materialLookup[this.version.pantries[i].back],
            this.materialLookup[this.version.pantries[i].base],
            this.version.grainDirection
          )

          this.items.push(pantry)

          // add interaction
          if (!this.customerView) {
            pantry.item.onMouseDown = this.selectItem
            pantry.item.onMouseDrag = this.dragSelected
            pantry.item.onMouseUp = this.itemMouseUp
          }
        }
      }
    },
    selectItem(e) {
      if (this.activeKey == 'space') return
      // store data from drag snapping calculations

      let parent

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].item == e.target) {
          parent = this.items[i]
          break
        }
      }
      this.dragStartData = {
        parent: parent,
        keyItemBounds: parent.item.children['outline'].bounds,
        pointerPos: e.point
      }

      if (this.activeKey != 'shift') {
        if (this.selectedItems.indexOf(e.target) == -1) {
          this.selectedItems = [e.target]
        }
      } else {
        for (let i = 0; i < this.selectedItems.length; i++) {
          if (e.target === this.selectedItems[i]) {
            this.selectedItems.splice(i, 1)
            return
          }
        }
        // loop didn't return so add to array
        this.selectedItems.push(e.target)
      }
    },
    dragSelected(e) {
      if (this.activeKey == 'space') return

      this.itemsDragged = true

      let offset = {
        x: e.point.x - this.dragStartData.pointerPos.x,
        y: e.point.y - this.dragStartData.pointerPos.y
      }

      let targetPos = {
        x: this.dragStartData.keyItemBounds.x + offset.x,
        y: this.dragStartData.keyItemBounds.y + offset.y
      }

      let keyOutlineBounds = this.dragStartData.parent.item.children['outline'].bounds

      // snapping tests
      for (let i = 0; i < this.items.length; i++) {
        // don't test items being dragged
        if (this.selectedItems.indexOf(this.items[i].item) == -1) {
          let testOutline = this.items[i].item.children['outline']

          // LEFT / RIGHT
          if (targetPos.x >= testOutline.bounds.x && targetPos.x <= testOutline.bounds.x + this.snappingThreshold) {
            targetPos.x = testOutline.bounds.x
          }
          if (
            targetPos.x >= testOutline.bounds.x + testOutline.bounds.width &&
            targetPos.x <= testOutline.bounds.x + testOutline.bounds.width + this.snappingThreshold
          ) {
            targetPos.x = testOutline.bounds.x + testOutline.bounds.width
          }
          if (
            targetPos.x + keyOutlineBounds.width >=
              testOutline.bounds.x + testOutline.bounds.width - this.snappingThreshold &&
            targetPos.x + keyOutlineBounds.width <= testOutline.bounds.x + testOutline.bounds.width
          ) {
            targetPos.x = testOutline.bounds.x + testOutline.bounds.width - keyOutlineBounds.width
          }
          if (
            targetPos.x + keyOutlineBounds.width >= testOutline.bounds.x - this.snappingThreshold &&
            targetPos.x + keyOutlineBounds.width <= testOutline.bounds.x
          ) {
            targetPos.x = testOutline.bounds.x - keyOutlineBounds.width
          }

          // TOP / BOTTOM
          if (targetPos.y >= testOutline.bounds.y && targetPos.y <= testOutline.bounds.y + this.snappingThreshold) {
            targetPos.y = testOutline.bounds.y
          }
          if (
            targetPos.y + keyOutlineBounds.height >=
              testOutline.bounds.y + testOutline.bounds.height - this.snappingThreshold &&
            targetPos.y + keyOutlineBounds.height <= testOutline.bounds.y + testOutline.bounds.height
          ) {
            targetPos.y = testOutline.bounds.y + testOutline.bounds.height - keyOutlineBounds.height
          }
          if (
            targetPos.y >= testOutline.bounds.y + testOutline.bounds.height &&
            targetPos.y <= testOutline.bounds.y + testOutline.bounds.height + this.snappingThreshold
          ) {
            targetPos.y = testOutline.bounds.y + testOutline.bounds.height
          }
          if (
            targetPos.y + keyOutlineBounds.height >= testOutline.bounds.y - this.snappingThreshold &&
            targetPos.y + keyOutlineBounds.height <= testOutline.bounds.y
          ) {
            targetPos.y = testOutline.bounds.y - keyOutlineBounds.height
          }
        }
      }

      // move everything
      let delta = {
        x: targetPos.x - keyOutlineBounds.x,
        y: targetPos.y - keyOutlineBounds.y
      }
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].translate(delta)
      }
    },
    itemMouseUp() {
      if (this.itemsDragged) {
        this.unsavedChanges = true
      }
    },
    regenerateCad() {
      paper.project.clear()
      paper.project.addLayer(new paper.Layer())
      paper.project.activeLayer.name = 'primary'

      // why do this again?
      this.frontsData = cadUtils.constructFrontsArray(this.version, this.materialLookup, this.handleLookup)
      this.coverPanelsData = cadUtils.constructCoverPanelArray(this.version, this.materialLookup)

      this.generateItems()
      this.centerView()
    },
    save() {
      paper.project.activeLayer.name = 'saved'
      let doc
      if (!this.version.customerVisualID) {
        doc = this.$db.collection('visuals').doc()
        doc.set({
          created: this.$firebase.firestore.Timestamp.now(),
          saved: this.$firebase.firestore.Timestamp.now(),
          json: paper.project.exportJSON()
        })
        this.$emit('customerVisualCreated', doc.id)
      } else {
        doc = this.$db.collection('visuals').doc(this.version.customerVisualID)
        doc.set({
          saved: this.$firebase.firestore.Timestamp.now(),
          json: paper.project.exportJSON()
        })
      }
      this.unsavedChanges = false
      paper.project.activeLayer.name = 'primary'
      if (this.userRequestedSave) {
        this.$store.commit('openSnackbar', { snackbarText: 'Saved' })
        this.userRequestedSave = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#customer-cad {
  width: 100%;
  .saved-options {
    display: flex;
    align-items: center;

    #publish-switch {
      display: flex;
      align-items: center;
      margin-right: 5px;
      span {
        margin-right: 10px;
      }
      .v-input {
        margin: 1px 0 0 0;
        padding: 0;
      }
    }
  }
  .action-bar {
    margin-bottom: 0px !important;
  }
  .app {
    position: relative;
    .controls {
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 20px 20px 0 0;
      display: flex;
    }
    canvas[resize] {
      width: 100%;
      display: block;
    }
    &.panning {
      cursor: move;
    }
  }
}
</style>
